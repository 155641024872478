import React, { useState, useEffect } from "react"
import Grid from "@material-ui/core/Grid"

import { useHistory } from "react-router-dom"
import Database from "../../utils/database"
import { LoadingIcon } from "./LoadingIcon"
import AssetDatabase from "../../utils/assetDatabase"
import fire from "../../config/firebase"
import axios from "axios"
import DefaultSettings from "../../defaultSettings/settings"
import Analytics from "../../config/anayltics"
import { v4 as uuidv4 } from "uuid"
import DatabaseIndexedDb from "../../IndexedDB/DatabaseIndexedDb"
import { useLiveQuery } from "dexie-react-hooks"
import CacheClient from "../../utils/cacheClients"
// const styles = (theme) => ({
//   root: {
//     flexGrow: 1,
//     position: "relative",
//     width: "100%",
//     minWidth: 300,
//   },
//   button: {
//     margin: theme.spacing(1),
//     backgroundColor: "#B9D949",
//   },
//   paper: {
//     padding: 0,
//     textAlign: "center",
//     color: theme.palette.text.secondary,
//   },
//   blocks: {
//     position: "absolute",
//     top: 0,
//     left: 0,
//   },
//   videoPlayer: {
//     width: 320,
//     height: 180,
//     zIndex: -1,
//     position: "absolute",
//     top: 0,
//     left: 0,
//   },
//   grow: {
//     width: "100%",
//     zIndex: -1,
//     position: "absolute",
//     top: 0,
//     left: 0,
//   },
//   menuButton: {
//     marginLeft: -12,
//     marginRight: 20,
//   },
// })

const WebAppLoader = (props) => {
  const chacheWebAppData = new DatabaseIndexedDb(props.webAppID)

  const [resetData, setResetData] = useState(0)
  const [previewDetails, setPreviewDetails] = useState(null)
  const [projectInfo, setProjectInfo] = useState(null)

  const [mainDownloads, setMainDownloads] = useState(0)
  let history = useHistory()
  // const [flagUrls, setFlagUrls] = useState([])
  // const [coverUrls, setCoverUrls] = useState([])
  let flagUrls = []
  let cacheFlagUrls = []
  let coverUrls = []
  let headerUrl = ""

  const errorHandleMessage =
    "Error loading app, please check your internet connection and try again"

  const getCachePeviewDetail = async ({
    previewDetails,
    projectInfo,
    headerUrl,
    flagUrls,
    keypadBackgroundCache,
  }) => {
    DefaultSettings.keypadHigher = projectInfo.webAppSettings.keypadHigh
    DefaultSettings.keypadLower = projectInfo.webAppSettings.keypadLower
    DefaultSettings.sliderColour = projectInfo.webAppSettings?.playerControls
      ? projectInfo.webAppSettings.playerControls
      : projectInfo.webAppSettings.secondary
    DefaultSettings.keypadTextColor = projectInfo.webAppSettings.keypadTextColor
    DefaultSettings.webAppBackground =
      projectInfo.webAppSettings.webAppBackground ||
      DefaultSettings.webAppBackground
    DefaultSettings.keypadBackground = keypadBackgroundCache

    props.onLoad({
      previewDetails,
      projectInfo,
      headerUrl,
      flagUrls,
    })
  }

  const getPreviewDetails = async () => {
    let previewDetails
    let manifestURL
    let manifestBlob
    let splashSizeIcons = []
    let keypadBackgroundCache
    try {
      previewDetails = await Database.getWebAppDetails(props.webAppID)
      console.log("previewDetails.data: ", previewDetails.data)
      if (!previewDetails.data) {
        let slugDetailsData = await Database.getWebAppDetailsByShortHand(
          props.webAppID
        )
        console.log("slugDetailsData: ", slugDetailsData)

        if (!slugDetailsData || slugDetailsData.length === 0) {
          throw "No content is available at this URL"
        } else {
          previewDetails = await Database.getWebAppDetails(
            slugDetailsData[0].data.webappID
          )
          history.push(slugDetailsData[0].data.webappID + "/0")
        }
      }
    } catch (error) {
      props.loadError(error, "webAppLoader")
      console.log("Failed to run getWebAppDetails, error: ", error)
      //stop executing function
      return
    }

    console.log("previewDetails: ", previewDetails)
    setPreviewDetails(previewDetails)

    const compDetails = {
      clientId: previewDetails.data.clientId,
      projectId: previewDetails.data.projectId,
      buildId: previewDetails.data.buildId,
    }
    console.log("compDetails:", props.webAppID, compDetails)

    AssetDatabase.useFirebase =
      compDetails.clientId +
      "/" +
      compDetails.projectId +
      "/" +
      compDetails.buildId
    AssetDatabase.useFirebaseLocation = previewDetails.data.location
      ? previewDetails.data.location
      : "eu"
    let url
    try {
      url = await AssetDatabase.getAsset("data.json", setMainDownloads)
    } catch (error) {
      props.loadError(errorHandleMessage, "webAppLoader")
      console.log("Failed to get data.json, error: ", error)
      //stop executing function
      return
    }
    console.log("url downloaded:", url)

    axios
      .get(url.link)
      .then(async (response) => {
        console.log("url.link: ", url.link)
        console.log("settings response.data: ", response.data)
        response.data.tours.sort((a, b) =>
          a.data.tourName > b.data.tourName ? 1 : -1
        )

        //only include the webapp enabled tours
        let webAppTours = response.data.tours.filter((tour) =>
          tour.data.hasOwnProperty("webApp")
        )
        console.log("webAppTours: ", webAppTours)

        const getFlags = (tour) => {
          AssetDatabase.useFirebase = `${compDetails.clientId}/${compDetails.projectId}/${compDetails.buildId}/${tour.id}`
          // compDetails.clientId +
          // "/" +
          // compDetails.projectId +
          // "/" +
          // compDetails.buildId +
          // "/" +
          // tour.id
          return AssetDatabase.getAsset(
            tour.data.webApp.tourIcon,
            setMainDownloads
          )
            .then(async function (result) {
              console.log("flag result: ", result)
              console.log("flag tour: ", tour)

              flagUrls.push({
                tourName: tour.data.tourName,
                url: result.link,
              })

              let reader = new FileReader()
              const response = await fetch(result.link)
              const fileBlob = await response.blob()
              reader.readAsDataURL(fileBlob)
              console.log("reader", reader)

              reader.onload = async function () {
                var base64result = reader.result.split(",")[1]
                let src = `data:image/png;base64, ${base64result}`
                cacheFlagUrls.push({
                  tourName: tour.data.tourName,
                  url: src,
                })
              }
            })
            .catch(function (err) {
              console.log("Failed to get flag url ", err)
            })
        }

        const getCovers = (tour) => {
          AssetDatabase.useFirebase =
            compDetails.clientId +
            "/" +
            compDetails.projectId +
            "/" +
            compDetails.buildId +
            "/" +
            tour.id
          return AssetDatabase.getAsset(
            tour.data.webApp.tourPreviewScreen,
            setMainDownloads
          )
            .then(function (result) {
              //not actually being used anywhere else, seems to be for downloading the cover images ahead of time?
              coverUrls.push({
                tourName: tour.data.tourName,
                url: result.link,
              })
            })
            .catch(function (err) {
              console.log("Failed to get cover url ", err)
            })
        }

        //change tab name
        window.document.title = response.data.projectDetails.data.clientName

        setProjectInfo(response.data)

        DefaultSettings.keypadHigher = response.data.webAppSettings.keypadHigh
        DefaultSettings.keypadLower = response.data.webAppSettings.keypadLower
        DefaultSettings.sliderColour = response.data.webAppSettings
          ?.playerControls
          ? response.data.webAppSettings.playerControls
          : response.data.webAppSettings.secondary
        DefaultSettings.keypadTextColor =
          response.data.webAppSettings.keypadTextColor
        DefaultSettings.webAppBackground =
          response.data.webAppSettings.webAppBackground ||
          DefaultSettings.webAppBackground

        let splashSizes = [
          "1136x640",
          "2436x1125",
          "1792x828",
          "828x1792",
          "1334x750",
          "1242x2688",
          "2208x1242",
          "1125x2436",
          "1242x2208",
          "2732x2048",
          "2688x1242",
          "2224x1668",
          "750x1334",
          "2048x2732",
          "2388x1668",
          "1668x2224",
          "640x1136",
          "1668x2388",
          "2048x1536",
          "1536x2048",
        ]

        const getSplashSize = (splashSize) =>
          AssetDatabase.getAsset(`splash_${splashSize}.png`)
            .then(function (result) {
              splashSizeIcons.push({
                element: `#icon_${splashSize}`,
                link: result.link,
              })
              document
                .querySelector(`#icon_${splashSize}`)
                .setAttribute("href", result.link)
            })
            .catch(function (err) {
              console.log("Failed to run getWebAppDetails, error: ", err)
            })

        let appleIcons = [
          { iconFile: "icon_48.png", element: "#webIcon", link: "" },
          { iconFile: "icon_48.png", element: "#appleIcon", link: "" },
          { iconFile: "icon_72.png", element: "#appleIcon72", link: "" },
          { iconFile: "icon_114.png", element: "#appleIcon114", link: "" },
          { iconFile: "icon_144.png", element: "#appleIcon144", link: "" },
          { iconFile: "icon_512.png", element: "#appleIcon180", link: "" },
        ]

        //link property populated in Promises below
        let androidIcons = [
          { iconFile: "icon_32.png", link: "" },
          { iconFile: "icon_48.png", link: "" },
          { iconFile: "icon_96.png", link: "" },
          { iconFile: "icon_144.png", link: "" },
          { iconFile: "icon_512.png", link: "" },
        ]

        const getAppleIcons = (appleIcon) =>
          AssetDatabase.getAsset(appleIcon.iconFile)
            .then(function (result) {
              appleIcon.link = result.link
              document
                .querySelector(appleIcon.element)
                .setAttribute("href", result.link)
            })
            .catch(function (err) {
              console.log("Failed to appleIcon, error: ", err)
            })

        const getAndroidIcons = (androidIcon, index) =>
          AssetDatabase.getAsset(androidIcon.iconFile)
            .then(function (result) {
              console.log("androidIcons result: ", result)
              console.log("androidIcons index: ", index)
              androidIcon.link = result.link
            })
            .catch(function (err) {
              console.log("Failed to androidIcon, error: ", err)
            })

        function createManifest() {
          AssetDatabase.useFirebase =
            compDetails.clientId +
            "/" +
            compDetails.projectId +
            "/" +
            compDetails.buildId +
            "/assets"
          const getAllAndroidIcons = androidIcons.map(getAndroidIcons)

          return Promise.all([...getAllAndroidIcons]).then((data) => {
            console.log("data from getAllAndroidIcons: ", data)
            console.log("androidIcons loaded! androidIcons: ", androidIcons)

            console.log("response.data manifest", response.data)

            // var myDynamicManifest = {
            //   id: props.webAppID,
            //   name: response.data.projectDetails.data.projectName,
            //   short_name: response.data.projectDetails.data.projectName,
            //   description: "Imagineear webapp",
            //   display: "fullscreen",
            //   start_url:
            //     response.data.projectDetails.data.clientId ===
            //     "wfxnHQW9uxoYJgoagVKb"
            //       ? `.`
            //       : `http://localhost:3000/webapp/${props.webAppID}`,
            //   // start_url:
            //   //   "https://imagineear-cms-staging.web.app/webapp/" +
            //   //   props.webAppID,
            //   background_color: "#000000",
            //   theme_color: "#FF0000",
            //   orientation: "portrait",
            //   icons: [
            //     {
            //       src: androidIcons[0].link,
            //       sizes: "32x32",
            //       type: "image/png",
            //     },
            //     {
            //       src: androidIcons[1].link,
            //       sizes: "48x48",
            //       type: "image/png",
            //     },
            //     {
            //       src: androidIcons[2].link,
            //       sizes: "96x96",
            //       type: "image/png",
            //     },
            //     {
            //       src: androidIcons[3].link,
            //       sizes: "144x144",
            //       type: "image/png",
            //     },
            //     {
            //       src: androidIcons[4].link,
            //       sizes: "512x512",
            //       type: "image/png",
            //     },
            //   ],

            //   screenshots: [
            //     {
            //       src: androidIcons[0].link,
            //       sizes: "32x32",
            //       type: "image/png",
            //     },
            //     {
            //       src: androidIcons[1].link,
            //       sizes: "48x48",
            //       type: "image/png",
            //     },
            //     {
            //       src: androidIcons[2].link,
            //       sizes: "96x96",
            //       type: "image/png",
            //     },
            //     {
            //       src: androidIcons[3].link,
            //       sizes: "144x144",
            //       type: "image/png",
            //     },
            //     {
            //       src: androidIcons[4].link,
            //       sizes: "512x512",
            //       type: "image/png",
            //     },
            //   ],
            // }

            var myDynamicManifest = {
              name: response.data.projectDetails.data.projectName,
              short_name: response.data.projectDetails.data.projectName,
              description: "Imagineear webapp",
              display: "standalone",
              start_url: `${window.location.origin}/webapp/${props.webAppID}`,
              scope: `${window.location.origin}/webapp/${props.webAppID}`,
              background_color: "#000000",
              theme_color: "#FF0000",
              orientation: "portrait",
              prefer_related_applications: true,
              // related_applications: [
              //   {
              //     platform: "web",
              //     url: `${window.location.origin}/webapp/${props.webAppID}`,
              //   },
              // ],
              icons: [
                {
                  src: androidIcons[0].link,
                  sizes: "32x32",
                  type: "image/png",
                },
                {
                  src: androidIcons[1].link,
                  sizes: "48x48",
                  type: "image/png",
                },
                {
                  src: androidIcons[2].link,
                  sizes: "96x96",
                  type: "image/png",
                },
                {
                  src: androidIcons[3].link,
                  sizes: "144x144",
                  type: "image/png",
                },
                {
                  src: androidIcons[4].link,
                  sizes: "512x512",
                  type: "image/png",
                },
              ],

              screenshots: [
                // {
                //   src: androidIcons[0].link,
                //   sizes: "32x32",
                //   type: "image/png",
                // },
                // {
                //   src: androidIcons[1].link,
                //   sizes: "48x48",
                //   type: "image/png",
                // },
                // {
                //   src: androidIcons[2].link,
                //   sizes: "96x96",
                //   type: "image/png",
                // },
                // {
                //   src: androidIcons[3].link,
                //   sizes: "144x144",
                //   type: "image/png",
                // },
                {
                  src: androidIcons[4].link,
                  sizes: "512x512",
                  type: "image/png",
                },
              ],
            }

            // var myDynamicManifest = {
            //   name: "Nicholas Runolfsdottir V",
            //   short_name: "Nicholas Runolfsdottir V",
            //   start_url: `http://localhost:3000/webapp/${props.webAppID}`,
            //   background_color: "#000000",
            //   theme_color: "#0f4a73",
            //   prefer_related_applications: true,
            //   display: "standalone",
            //   icons: [
            //     {
            //       src: `http://localhost:3000/favicon.ico`,
            //       sizes: "64x64 32x32 24x24 16x16",
            //       type: "image/x-icon",
            //       purpose: "any maskable",
            //     },
            //     {
            //       src: `http://localhost:3000/logo192.png`,
            //       type: "image/png",
            //       sizes: "192x192",
            //       purpose: "any maskable",
            //     },
            //     {
            //       src: `http://localhost:3000/logo512.png`,
            //       type: "image/png",
            //       sizes: "512x512",
            //       purpose: "any maskable",
            //     },
            //   ],
            // }

            console.log("myDynamicManifest: ", myDynamicManifest, response.data)
            const stringManifest = JSON.stringify(myDynamicManifest)
            const blob = new Blob([stringManifest], {
              type: "application/json",
            })

            manifestBlob = blob

            console.log("manifest blob", blob)

            manifestURL = URL.createObjectURL(blob)

            // manifestURL =
            //   "data:application/json;charset=utf-8," +
            //   encodeURIComponent(stringManifest)

            document
              .querySelector("#my-manifest-placeholder")
              .setAttribute("href", manifestURL)
          })
        }

        //hardcoded file asset names from builds.js/Generate web app build
        const loadHeader = () => {
          return AssetDatabase.getAsset("header_icon.png", setMainDownloads)
            .then(async function (result) {
              headerUrl = result.link
            })
            .catch((error) => {
              console.log("Failed to get header url ", error)
            })
        }

        const loadKeypadBackground = () => {
          return AssetDatabase.getAsset(
            "keypad_background.png",
            setMainDownloads
          )
            .then(function (result) {
              if (result.link !== undefined) {
                console.log("set keypadBackground to" + result.link)
                keypadBackgroundCache = result.link
                DefaultSettings.keypadBackground = result.link
              }
            })
            .catch((error) => {
              console.log("Failed to get keypad background url ", error)
            })
        }

        const getAllFlags = webAppTours.map(getFlags)
        const getAllCovers = webAppTours.map(getCovers)

        return Promise.all([...getAllFlags, ...getAllCovers]).then((data) => {
          //need to do flags and covers separately since they use a different AssetDatabase.useFirebase path
          console.log("flags and covers loaded!")

          AssetDatabase.useFirebase =
            compDetails.clientId +
            "/" +
            compDetails.projectId +
            "/" +
            compDetails.buildId +
            "/assets"

          const getAllSplashSizes = splashSizes.map(getSplashSize)
          const getAllAppleIcons = appleIcons.map(getAppleIcons)

          const grabAssets = Promise.all([
            ...getAllSplashSizes,
            ...getAllAppleIcons,
            createManifest(), //getAndroidIcons called in here
            loadHeader(),
            loadKeypadBackground(),
          ])
          grabAssets.then(async (data) => {
            console.log("everything loaded!", data)

            const webDetails = {
              previewDetails,
              projectInfo: response.data,
              headerUrl,
              flagUrls,
            }

            if (CacheClient.checkClient(compDetails.clientId)) {
              function getHeaderCacheUrl() {
                return new Promise(async (res, rej) => {
                  let reader = new FileReader()
                  const response = await fetch(headerUrl)
                  const fileBlob = await response.blob()
                  reader.readAsDataURL(fileBlob)

                  reader.onload = async function () {
                    var base64result = reader.result.split(",")[1]
                    res(`data:image/png;base64, ${base64result}`)
                  }
                })
              }

              let cacheHeaderUrl = await getHeaderCacheUrl()

              console.log("webDetails: ", webDetails)

              await chacheWebAppData.addWebappDetails({
                ...webDetails,
                flagUrls: cacheFlagUrls,
                headerUrl: cacheHeaderUrl,
                manifestURL: manifestBlob,
                appleIcons,
                splashSizeIcons,
                keypadBackgroundCache,
              })

              await chacheWebAppData.addProjectName(
                webDetails.projectInfo.projectDetails.data.projectName
              )
            }

            console.log(
              "compDetails, webDetails, response :>> ",
              compDetails,
              webDetails,
              response
            )

            const { projectInfo } = webDetails

            console.log(
              "projectInfo.tours.ength :>> ",
              projectInfo.tours.length
            )

            if (CacheClient.checkPWA(compDetails.projectId)) {
              window.location.reload()
            } else {
              await triggerAnalytics(projectInfo.projectDetails.data)
              props.onLoad(webDetails)
            }
          })
        })
      })
      .catch((error) => {
        props.loadError(errorHandleMessage, "webAppLoader")
        console.log("Failed to get axios response of data.json, error: ", error)
      })
  }

  const triggerAnalytics = async (props) => {
    console.log(" triggerEventsprops", props)
    const { clientId, clientName, projectName, projectCode } = props
    const projectListArray = await Database.getClientProjects(clientId)
    console.log("projectListArray :>> ", projectListArray)

    if (projectListArray.length < 2) {
      if (
        localStorage &&
        localStorage.getItem("TBP-userID") &&
        localStorage.getItem("TBP-session-id")
      ) {
        const userID = localStorage.getItem("TBP-userID")
        const session_id = localStorage.getItem("TBP-session-id")
        const app_namecustom =
          clientName === "Historic Environment Scotland" ? "HES" : clientName

        // Analytics.eventTrigger(response.data.projectDetails.data.clientName + "_webapp", {
        //   app_name: response.data.projectDetails.data.clientName + "_webapp",
        Analytics.eventTrigger(app_namecustom + "_webapp", {
          app_name: app_namecustom + "_webapp",
          current_project_name: projectName,
          current_project_code: projectCode,
          user_id: userID,
          userID: userID,
          session_id: session_id,
          // duration: duration.asSeconds(),
        })
      } else {
        const session_id = uuidv4()
        const app_namecustom =
          clientName === "Historic Environment Scotland" ? "HES" : clientName
        // Analytics.eventTrigger(response.data.projectDetails.data.clientName + "_webapp", {
        //   app_name: response.data.projectDetails.data.clientName + "_webapp",
        Analytics.eventTrigger(app_namecustom + "_webapp", {
          app_name: app_namecustom + "_webapp",
          current_project_name: projectName,
          current_project_code: projectCode,
          session_id: session_id,
          // duration: duration.asSeconds(),
        })
      }
    } else {
      if (
        localStorage &&
        localStorage.getItem("TBP-userID") &&
        localStorage.getItem("TBP-session-id")
      ) {
        const userID = localStorage.getItem("TBP-userID")
        const session_id = localStorage.getItem("TBP-session-id")

        const app_namecustom =
          clientName === "Historic Environment Scotland" ? "HES" : clientName

        Analytics.eventTrigger(
          // response.data.projectDetails.data.clientName + "_webapp", {
          app_namecustom + "_webapp",
          {
            user_id: userID,
            userID: userID,
            session_id: session_id,
          }
        )

        // Analytics.eventTrigger(`${response.data.projectDetails.data.clientName}_${webDetails.projectInfo.projectDetails.data.projectName}_webapp`, {
        //   app_name: response.data.projectDetails.data.clientName + "_webapp",
        Analytics.eventTrigger(`${app_namecustom}_${projectName}_webapp`, {
          app_name: app_namecustom + "_webapp",
          current_project_name: projectName,
          current_project_code: projectCode,
          user_id: userID,
          userID: userID,
          session_id: session_id,
          // duration: duration.asSeconds(),
        })

        fire.analytics().setUserProperties({
          // app_name: response.data.projectDetails.data.clientName + "_webapp",
          app_name: app_namecustom + "_webapp",
          current_project_name: projectName,
          current_project_code: projectCode,
          user_id: userID,
          userID: userID,
          session_id: session_id,
          // current_tour_name: component.data.tourName,
          // tour_id: component.data.tourId,
          // component_id: component.id,
          // type: component.data.type,
          // tour_name: component.tourDetails.data.tourName,
          // tour_code: component.tourDetails.data.tourCode,
          // section: component.data.section ? component.data.section : "N/A",
          // title: component.data.title,
        })
      } else {
        const session_id = uuidv4()
        localStorage.setItem("TBP-session-id", session_id)

        const app_namecustom =
          clientName === "Historic Environment Scotland" ? "HES" : clientName

        Analytics.eventTrigger(
          // response.data.projectDetails.data.clientName + "_webapp", {
          app_namecustom + "_webapp",
          {
            session_id: session_id,
          }
        )

        // Analytics.eventTrigger(`${response.data.projectDetails.data.clientName}_${webDetails.projectInfo.projectDetails.data.projectName}_webapp`, {
        //   app_name: response.data.projectDetails.data.clientName + "_webapp",
        Analytics.eventTrigger(`${app_namecustom}_${projectName}_webapp`, {
          app_name: app_namecustom + "_webapp",
          current_project_name: projectName,
          current_project_code: projectCode,
          session_id: session_id,
          // duration: duration.asSeconds(),
        })

        fire.analytics().setUserProperties({
          // app_name: response.data.projectDetails.data.clientName + "_webapp",
          app_name: app_namecustom + "_webapp",
          current_project_name: projectName,
          current_project_code: projectCode,
          session_id: session_id,
          // current_tour_name: component.data.tourName,
          // tour_id: component.data.tourId,
          // component_id: component.id,
          // type: component.data.type,
          // tour_name: component.tourDetails.data.tourName,
          // tour_code: component.tourDetails.data.tourCode,
          // section: component.data.section ? component.data.section : "N/A",
          // title: component.data.title,
        })
      }
    }
    //fire.analytics().setUserId(uuidv4())
  }

  useEffect(() => {
    const checkCache = async () => {
      let webDetails = await chacheWebAppData.db.tours
        .filter((data) => data.name === "webAppDetail")
        .toArray()

      console.log("webDetails", webDetails)

      if (navigator.onLine) {
        if (webDetails.length > 0) {
          getCachePeviewDetail(webDetails[0].data)
          triggerAnalytics(webDetails[0].data.projectInfo.projectDetails.data)

          // console.log(
          //   "document.querySelector",
          //   document.querySelector("#my-manifest-placeholder")
          // )

          const manifestURL = URL.createObjectURL(
            webDetails[0].data.manifestURL
          )

          document
            .querySelector("#my-manifest-placeholder")
            .setAttribute("href", manifestURL)

          webDetails[0].data.splashSizeIcons.forEach((item) => {
            document.querySelector(item.element).setAttribute("href", item.link)
          })
        } else {
          getPreviewDetails()
        }
      } else {
        if (webDetails.length > 0) {
          getCachePeviewDetail(webDetails[0].data)
        } else {
          props.loadError(errorHandleMessage, "webAppLoader")
        }
      }
    }

    checkCache()
  }, [resetData])

  return (
    <Grid
      container
      justify="center"
      style={{
        backgroundColor: DefaultSettings.webAppBackground,
        margin: "0 auto",
        width: "100vw",
      }}
    >
      <LoadingIcon />
    </Grid>
  )
}
export default WebAppLoader
