import React, {
  Component,
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { FormControl, Grid, InputLabel, Select } from "@material-ui/core"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import DropDownMenu from "../../dropDownMenu"
import { ItemContext } from "../ComponentLoader"
import Blocks, {
  getRandomColor,
} from "../tourPlayer/InteractiveLayer/blocks/Blocks"

import Typography from "@material-ui/core/Typography"
import AssetDatabase, { dbx } from "../../../utils/assetDatabase"

import SelectAsset from "../settings/SelectAsset"
import AutoAssignButton from "../AutoAssignButton"
import ComponentFullImage from "../ComponentFullImage"
import DefaultSettings from "../../../defaultSettings/settings"
import ReactPlayer from "react-player"
import * as mm from "music-metadata-browser"
import { useLiveQuery } from "dexie-react-hooks"
import { addNewTourImageSrc, db } from "../../../IndexedDB/db"
import { fetchTempImagePath } from "../../../utils/getTempImagePath"
import DatabaseIndexedDb from "../../../IndexedDB/DatabaseIndexedDb"
import SegmentedListItem from "./SegmentedListComponent/SegmentedListItem"
import SegmentedListItemEditor from "./SegmentedListComponent/SegmentedListItemEditor"
import TourExporter from "../../../utils/buildTour"
import KeyboardEventHandler from "react-keyboard-event-handler"

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    position: "relative",
    minWidth: 300,
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#B9D949",
  },
  paper: {
    padding: 0,
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  blocks: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  videoPlayer: {
    width: 320,
    height: 180,
    zIndex: -1,
    position: "absolute",
    top: 0,
    left: 0,
  },
  grow: {
    width: "100%",
    zIndex: -1,
    position: "absolute",
    top: 0,
    left: 0,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
})

export const SegmentListSettings = {
  type: "SegmentList",
  title: "Segment List",
  support: ["iwa", "tourbuilder", "kiosk"],
  img: require("../../../images/list_component.png"),
  color: "#525F11",
}
export const SegmentListAdvancedSettings = {
  barMode: "C", ///possible values 'C':Combo,'T':top, 'B': Bottom
  reverse: false,
  listItems: [],
  selected: [],
  size: 6,
  assets: {
    main: {
      name: "",
    },
    audio: {
      name: "",
    },
  },
}

// export const SegementListValidation = (item ,siblings) => {

//   let warning = []

//   item.data.advancedSettings.listItems.forEach(
//     (item, i) => {
//       console.log("item", item)
//       let checkLink = []
//       let emptyBlocksLink = []
//       let isAnyBlockLinkFound = false

//       let blocks = ["A", "B", "C", "D"]
//       blocks.forEach((val) => {
//         const link = item[val]?.link || ""
//         const component = siblings.find(
//           (cid) => cid.id === link
//         )
//         if (component) {
//           isAnyBlockLinkFound = true
//           const data = TourExporter.isMedia(
//             component.data
//           )
//           if (data) {
//             checkLink.push({
//               block: val,
//               value: component.data.orientation,
//             })
//           }
//         } else {
//           emptyBlocksLink.push(val)
//         }
//       })
//       // console.log("checkLink", checkLink)
//       if (checkLink.length > 1) {
//         const distObj = {
//           pot: "",
//           lan: "",
//         }

//         checkLink.forEach((val) => {
//           if (val.value === "Portrait") {
//             distObj.pot =
//               distObj.pot === ""
//                 ? `Block ${val.block}`
//                 : `${distObj.pot}, Block ${val.block}`
//           } else {
//             distObj.lan =
//               distObj.lan === ""
//                 ? `Block ${val.block}`
//                 : `${distObj.pot}, Block ${val.block}`
//           }
//         })
//         warning.push({
//           title: `Slice ${i + 1}`,
//           desc: `${distObj.pot} contains Portrait whereas ${distObj.lan} contains Fullscreen`,
//         })
//       }

//       if (
//         isAnyBlockLinkFound &&
//         emptyBlocksLink.length > 0
//       ) {
//         warning.push({
//           title: `Slice ${i + 1}`,
//           desc: `Link is missing in ${emptyBlocksLink.join(
//             ", "
//           )} blocks. Please add those links before save. `,
//         })
//       }
//     }
//   )

//   return warning

// }
export const SegmentListCheckForWarnings = (component, siblings) => {
  let warnings = []
  if (!component.data.advancedSettings.assets.main.name) {
    warnings.push({
      type: "warning",
      title: `${component.data.title} does not have a background image`,
    })
  }

  for (
    let index = 0;
    index < component.data.advancedSettings.listItems.length;
    index++
  ) {
    const listItem = component.data.advancedSettings.listItems[index]

    if (listItem.single) {
      const comp = siblings.find((obj) => obj.id === listItem?.link || "")
      if (
        (listItem.exitTour ||
          listItem.toKeypad ||
          listItem.backToPrevious ||
          listItem.returnLink ||
          comp) &&
        !listItem.assets.name
      ) {
        warnings.push({
          type: "warning",
          title: `ListItem-${index} - No image assigned to list`,
        })
      }
    } else {
      const blocks = ["A", "B", "C", "D"]
      let emptyBlocksLink = []
      const checkLink = []
      const blockWarn = {
        landscape: "",
        potrait: "",
      }

      blocks.forEach((char) => {
        const charBlock = listItem[char]
        const comp = siblings.find((obj) => obj.id === charBlock?.link || "")
        if (
          charBlock.exitTour ||
          charBlock.toKeypad ||
          charBlock.backToPrevious ||
          charBlock.returnLink ||
          comp
        ) {
          if (comp && TourExporter.isMedia(comp.data)) {
            checkLink.push({ block: char, value: comp.data.orientation })
          }
        } else {
          emptyBlocksLink.push(char)
        }
      })

      if (emptyBlocksLink.length < 4 && !listItem.assets?.name) {
        warnings.push({
          type: "warning",
          title: `ListItem-${index} - No image assigned to list`,
        })
      }

      if (emptyBlocksLink.length !== 0 && emptyBlocksLink.length !== 4) {
        warnings.push({
          type: "warning",
          title: `ListItem-${index} - Link is missing in ${emptyBlocksLink.join(
            ", "
          )} blocks.`,
        })
      }

      if (checkLink.length > 0) {
        checkLink.forEach((val) => {
          if (val.value === "Portrait") {
            blockWarn.potrait =
              blockWarn.potrait === ""
                ? `Block ${val.block}`
                : `${blockWarn.potrait}, Block ${val.block}`
          } else {
            blockWarn.landscape =
              blockWarn.landscape === ""
                ? `Block ${val.block}`
                : `${blockWarn.landscape}, Block ${val.block}`
          }
        })

        if (blockWarn.landscape && blockWarn.potrait) {
          warnings.push({
            type: "warning",
            title: `ListItem-${index} - ${blockWarn.potrait} contains Portrait whereas ${blockWarn.landscape} contains Fullscreen`,
          })
        }
      }
    }
  }

  // if(buttonLinkNotFound){
  //   warnings.push({
  //     type: "warning",
  //     title: `${component.data.title} has list items assigned with no active link`,
  //   })
  // }
  console.log("list warnings: ", warnings)

  /// review all assets, e.g. size, bitrate and resolution
  return warnings
}
export const SegmentListAssets = (component) => {
  let assets = []
  if (component.data.advancedSettings.assets.main.name != "") {
    assets.push(component.data.advancedSettings.assets.main.name)
  }
  for (
    let index = 0;
    index < component.data.advancedSettings.listItems.length;
    index++
  ) {
    const listItem = component.data.advancedSettings.listItems[index]
    console.log("list item required:", listItem.assets.name, component.id)
    if (listItem.assets.name != "") {
      assets.push(listItem.assets.name)
    }
  }
  return assets
}

export const SegmentListPlayerLayers = (props) => {
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    TourPlayer,
    portrait,
    device,
    scrollList,
    setScrollList,
    singleBlockClickForSegementList,
    setSingleBlockClickForSegementList,
  } = itemContext
  const { advancedSettings } = item.data
  const { barMode } = item.data.advancedSettings

  const scrollContainerRef = useRef(null)
  // const [segmentListScroll, setSegmentListScroll] = useState(localStorage.getItem("list-scroll") || 0)
  const [mouseDown, setMouseDown] = useState(false)
  const [altDown, setAltDown] = useState(false)
  const [selectedColors, setSelectedColors] = useState([])
  const [selectedBlocks, setSelectedBlocks] = useState([])
  let middleRows = null
  const [count, setCount] = useState(0)
  let topPercentage = (100 / 1280) * 136
  let middlePercentage = (100 / 1280) * 100.8
  let bottomPercentage = (100 / 1280) * 136
  if (device.data.deviceName === "Kiosk") {
    topPercentage = (100 / 1280) * 65
    middlePercentage = (100 / 1280) * 115
    bottomPercentage = (100 / 1280) * 65
  }

  useEffect(() => {
    if (scrollContainerRef.current && previewMode) {
      const element = scrollContainerRef.current
      const scrollTop = scrollList?.[item.id] || 0
      setTimeout(() => {
        element.scrollTo({
          top: scrollTop,
          left: 0,
        })
        handleSaveScrollPosition(0)
      }, 0)
    }
  }, [scrollContainerRef.current, previewMode])

  const handleSaveScrollPosition = (value) => {
    if (scrollContainerRef.current) {
      const scrollTop =
        value !== undefined ? value : scrollContainerRef.current.scrollTop
      setScrollList(item.id, scrollTop)
      // setSegmentListScroll(scrollTop);
      // localStorage.setItem("list-scroll", scrollTop)
    }
  }

  const createInteractiveLayer = () => {
    //count++;
    //console.log('snapshots updated:',count);
    const interactBlocks = (
      <Blocks
        key={"Blocks" + (count + 1)}
        item={item}
        settings={{
          topBlocks: {
            rows: [1],
            percentageHeight: topPercentage + "%",
            used: barMode === "C" || barMode === "T" ? true : false,
          },

          middleBlocks: {
            rows: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
            percentageHeight: middlePercentage + "%",
            used: false,
          },
          bottomBlocks: {
            rows: [12],
            percentageHeight: bottomPercentage + "%",
            used: barMode === "C" || barMode === "B" ? true : false,
          },
        }}
      />
    )

    //console.log('snapshots updated: new blocks');

    return interactBlocks
  }
  useEffect(() => {
    console.log("snapshots updated now", item)
    setInteractiveLayer(createInteractiveLayer())
    setCount(count + 1)
    //updateItem(item,previewMode)
  }, [item.data.advancedSettings.barMode, item.data.advancedSettings.listItems])
  const [interactiveLayer, setInteractiveLayer] = useState(
    createInteractiveLayer()
  )

  let listItems = []
  useEffect(() => {
    console.log("list items changed", item.data.advancedSettings.listItems)
  }, [item.data.advancedSettings.listItems])
  let listCount = 0
  const [currentItem, setCurrentItem] = React.useState(null)
  const onItemSettingClosing = async (componentInfo, action) => {
    console.log(currentItem)
    if (action === "save") {
      currentItem.title = componentInfo.title
      console.log("save: " + JSON.stringify(item))
      updateItem(item, previewMode, "list component")
    }
    setCurrentItem(null)
  }

  console.log("setSelectedBlocks", selectedBlocks)
  // console.log("color", advancedSettings.selected)
  useEffect(() => {
    let colors = [
      ...Array(advancedSettings.listItems.length)
        .fill(0)
        .map((val) => ({})),
    ]

    advancedSettings.selected.forEach((val) => {
      let randColor = getRandomColor()
      // console.log("randColor", randColor)
      val.split(",").forEach((data) => {
        if (data.includes("_")) {
          const [index, char] = data.split("_")
          const isSingle = advancedSettings.listItems[index].single
          if (char) {
            if (!isSingle) {
              colors[index][char] = randColor
            }
          } else {
            if (isSingle) {
              colors[index] = randColor
            }
          }
        } else {
          colors[data] = randColor
        }
        // const [index, char] = data.split("_")
        // if (char) {
        //   const isSingle = advancedSettings.listItems[index].single
        //   if (isSingle) {
        //     colors[index] = randColor
        //   } else {
        //     colors[index][char] = randColor
        //   }
        // } else {
        //   colors[index] = randColor
        // }
      })
    })

    console.log("colors", colors)

    if (singleBlockClickForSegementList) {
      setSingleBlockClickForSegementList(false)
    }

    setSelectedColors(colors)
  }, [
    advancedSettings.selected,
    advancedSettings?.selected?.length,
    advancedSettings.listItems,
    singleBlockClickForSegementList,
  ])

  for (let index = 0; index < advancedSettings.listItems.length; index++) {
    const element = advancedSettings.listItems[index]

    if (element.assets === null) {
      element.assets = {}
    }
    console.log("update list item render", element)
    const listItem = (
      <SegmentedListItem
        index={index}
        lockColors={selectedColors[index]}
        element={advancedSettings.listItems}
        listItem={element}
        updateListItem={(item) => {
          console.log(item.data.advancedSettings.listItems, item)
        }}
        listCount={listCount}
        setCurrentItem={setCurrentItem}
        topPercentage={topPercentage}
        barMode={barMode}
        mouseDown={mouseDown}
        setMouseDown={setMouseDown}
        onSelectBlock={(char) => {
          if (
            !selectedBlocks.includes(
              element.single ? `${index}` : `${index}_${char}`
            )
          ) {
            setSelectedBlocks([
              ...selectedBlocks,
              element.single ? `${index}` : `${index}_${char}`,
            ])
          }
        }}
        onCheckBlock={(char) => {
          return selectedBlocks.includes(
            element.single ? `${index}` : `${index}_${char}`
          )
        }}
        onDeleteBlock={(char) => {
          if (char === "ALL") {
            setSelectedBlocks([])
          } else if (char) {
            setSelectedBlocks([
              ...selectedBlocks.filter((val) => val !== `${index}_${char}`),
            ])
          } else {
            setSelectedBlocks([
              ...selectedBlocks.filter((val) => val !== `${index}`),
            ])
          }
        }}
        selectedBlocks={selectedBlocks}
        altDown={altDown}
        handleSaveScrollPosition={handleSaveScrollPosition}
      />
    )

    listItems.push(listItem)
    listCount++
  }
  let adjustedPercentage =
    barMode === "C"
      ? middlePercentage * 10
      : middlePercentage * 10 + topPercentage
  if (barMode === "N") {
    adjustedPercentage = 100
  }
  middleRows = (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: barMode === "C" || barMode === "T" ? topPercentage + "%" : 0,
        right: 0,
        bottom: 0,
        width: (TourPlayer.width / 100) * (portrait ? 100 : adjustedPercentage),
        height:
          (TourPlayer.height / 100) * (!portrait ? 100 : adjustedPercentage),
        transformOrigin: "top left",
        transform: `rotate(${portrait ? 0 : -90}deg) translate(${
          portrait ? 0 : -100
        }%)`,
      }}
    >
      <Grid
        style={{
          position: "relative",
          width:
            (TourPlayer.width / 100) * (portrait ? 100 : adjustedPercentage),
          height:
            (TourPlayer.height / 100) * (!portrait ? 100 : adjustedPercentage),
        }}
      >
        <div
          ref={scrollContainerRef}
          className="scrollContent"
          style={{
            overflow: "auto",
            scrollDirection: "",
            WebkitScrollBar: "",
            width: "100%",
            height: "100%",
            overflowY: "overlay",
            webkitsc: "touch",
            maxHeight: "100%",
          }}
          onMouseDown={() => setMouseDown(true)}
          onMouseUp={() => setMouseDown(false)}
        >
          {listItems}
        </div>
        {/* <ListItemSettings
          open={currentItem !== null ? true : false}
          currentItem={
            currentItem === null
              ? { assets: {}, link: null, title: "" }
              : currentItem
          }
          onClose={onItemSettingClosing}
        /> */}
      </Grid>
    </div>
  )
  //blockLinkTo = interactBlocks.blockLinkTo;
  /*const interactiveLayer = (
      <div  style={{position:'absolute',left: 0, top: 0, right: 0, bottom: 0, width:'100%',height:'100%'}}>
        {interactiveHolder}
            {middleRows}
      </div>
    )*/
  let interactiveHolder = (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        width: "100%",
        height: "100%",
      }}
    >
      <Grid
        container
        spacing={0}
        style={{
          position: "relative",
          display: "inline-block",
          flexGrow: 1,
          flex: 1,
          width: "100%",
          height: "100%",
        }}
      >
        <Grid
          style={{
            position: "relative",
            display: "inline-block",
            flexGrow: 1,
            flex: 1,
            width: "100%",
            height: "100%",
          }}
        >
          {interactiveLayer}
        </Grid>
      </Grid>
    </div>
  )
  const [bgAudioAssetFullPath, setbgAudioAssetFullPath] = useState("")
  const { audio, main } = item.data.advancedSettings.assets
  let componentAudio

  const getTempAudioPath = async (_audioname) => {
    let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${_audioname}`
    console.log("___path___ " + _path)
    if (
      componentAudio.length > 0 &&
      componentAudio[0].imageName === _audioname
    ) {
      setbgAudioAssetFullPath(componentAudio[0].backgroundUrl)
    } else {
      await AssetDatabase.getAsset(_path)
        .then(async (res) => {
          console.log("res: ", res)
          console.log("res.link: ", res.link)
          const metadata = await mm.fetchFromUrl(res.link, {
            duration: true,
          })
          console.log("metadata :>> ", metadata)
          const assetType = _path.split(".").pop()

          try {
            let reader = new FileReader()
            const fileBlob = await (await fetch(res.link)).blob()
            reader.readAsDataURL(fileBlob) // converts the blob to base64 and calls onload
            reader.onload = function () {
              if (["mp3", "aac"].includes(assetType) === true) {
                var base64result = reader.result.split(",")[1]
                let src = `data:audio/mpeg;base64, ${base64result}`
                setbgAudioAssetFullPath(src)
                if (item.data?.enableCache && item.data.enableCache === true) {
                  fetchTempImagePath(_audioname, item, {
                    ...metadata,
                    fileBlob,
                  })
                }
              }
            }
          } catch (err) {
            setbgAudioAssetFullPath(res.link)
          }
        })
        .catch((err) => console.log(err))
    }
  }

  useEffect(() => {
    const test = async () => {
      const indexedDBObj = new DatabaseIndexedDb(item.tourDetails.id)
      componentAudio = await indexedDBObj.db.tours
        .where("name")
        .equals(audio?.name ? audio.name : "")
        .toArray()
      if (
        item.data.advancedSettings.assets.audio?.name &&
        componentAudio !== undefined
      ) {
        getTempAudioPath(item.data.advancedSettings.assets.audio.name)
      }
    }
    test()
  }, [item.data.advancedSettings.assets.audio])

  // const getTempImagePath = async (_imgname) => {
  //   // setbgAssetFullPath("")
  //   let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${_imgname}`
  //   if((componentImage.length > 0) && (componentImage[0].imageName === _imgname)) {
  //     console.log('focus here :>> ');
  //     setbgAssetFullPath(componentImage[0].backgroundUrl);
  //   }
  //   else {
  //     await AssetDatabase.getAsset(_path).then((res) => {
  //       // if (!["mp3", "aac", "mp4"].includes(assetType)) {
  //         let reader = new FileReader()

  //         reader.readAsDataURL(res.fileBlob) // converts the blob to base64 and calls onload
  //         reader.onload = function () {
  //           var base64result = reader.result.split(",")[1]
  //           let src = `data:image/png;base64, ${base64result}`
  //           console.log('src inside else block :>> ', src);
  //           setbgAssetFullPath(src)
  //           if((item.data?.enableCache) &&
  //           (item.data.enableCache == true) ) {
  //             addNewTourImageSrc(src, _imgname)
  //           }
  //         }
  //       // }
  //       setbgAssetFullPath(res.link)
  //     })
  //   }
  // }

  let backgroundLayer = null
  // if (bgAssetFullPath != "") {
  if (item.data.advancedSettings.assets.main?.name) {
    // console.log("bgAssetFullPath:" + bgAssetFullPath)
    backgroundLayer = (
      <ComponentFullImage
        key={"fullImage"}
        item={item}
        asset={item.data.advancedSettings.assets.main}
        viewStyle={{
          postion: "relative",
          width: TourPlayer.width,
          height: TourPlayer.height,
          backgroundColor: DefaultSettings.webAppBackground,
          objectFit: "fill",
          transformOrigin: "top left",
          transform: `rotate(${portrait ? 0 : -90}deg) translate(${
            portrait ? 0 : -100
          }%)`,
        }}
        alt="logo"
      />
    )
  }
  /*else{
      backgroundLayer=( <img src={item.data.assets.imageBackground} style={{width:'100%',height:'100%', objectFit:'fill'}} alt="logo" />);
    }*/

  ///return the three layers
  return (
    <Grid style={{ width: TourPlayer.width, height: TourPlayer.height }}>
      {backgroundLayer}
      {interactiveHolder}
      {middleRows}

      <KeyboardEventHandler
        handleKeys={["alt"]}
        onKeyEvent={(key, e) => {
          //console.log('only handle "a" key')
          if (!altDown) {
            console.log("altDown to true")
            setAltDown(true)
            //Do whatever when esc is pressed
          }
        }}
      />
      <KeyboardEventHandler
        handleKeys={["alt"]}
        handleEventType={"keyup"}
        onKeyEvent={(key, e) => {
          //console.log('only handle "a" key')
          if (altDown) {
            console.log("altDown to false")
            setAltDown(false)
            //Do whatever when esc is pressed
          }
        }}
      />
      <ReactPlayer
        //onReady={}
        url={bgAudioAssetFullPath}
        //onDuration={handleDuration}
        //onEnded={gotoNextItem}
        // onPause={() => {
        //   if (state.playing && !player.getInternalPlayer().ended) {
        //     setState({ ...state, playing: false })
        //   }
        // }}
        // onPlay={() => {
        //   if (!state.playing) {
        //     setState({ ...state, playing: true })
        //   }
        // }}
        // onError={() => {
        //   setState({ ...state, playing: false })
        // }}
        //onProgress={handleProgress}
        playing={true}
        playsinline={true}
        volume={DefaultSettings.volume}
        //width={myComponenetWidth}
        //height={myComponenetHeight}
        style={{ postion: "relative" }}
        config={{
          file: {
            forceAudio: true,
          },
        }}
      />
    </Grid>
  )
}
export const SegmentListPlayerOptions = (props) => {
  const itemContext = useContext(ItemContext)
  const { item, previewMode, siblings, updateItem, handleAssetsViewerOpen } =
    itemContext
  const { advancedSettings } = item.data

  const mainProjectPath = `${item.clientId}/${item.projectId}`
  const [openAssetBrower, setOpenAssetBrower] = React.useState(false)
  const [openPresetAssetBrower, setOpenPresetAssetBrower] =
    React.useState(false)

  const assetBckRequirements = {
    presentMatches: false,
    data: [
      {
        type: "jpg",
        title: "",
      },
    ],
  }

  const tempCode = ("000" + item.data.code).slice(-4)
  const assetPresetRequirements = {
    presentMatches: true,
    data: [
      {
        type: "jpg",
        title: "Background image",
        code: tempCode + "_00",
      },
    ],
  }
  for (
    let index = 0;
    index < item.data.advancedSettings.listItems.length;
    index++
  ) {
    //const element = array[index];
    assetPresetRequirements.data.push({
      type: "jpg",
      title: "item" + (index + 1),
      index: index,
      code: tempCode + "_" + ("00" + (index + 1)).slice(-2),
    })
  }

  const handleClose = (assetRequirements) => {
    if (assetRequirements !== undefined) {
      let BckImage = assetRequirements.data.find(
        (obj) => obj.title === "Background image"
      )

      if (BckImage.match !== undefined) {
        console.log("BckImage.match.fileName:" + BckImage.match)
        console.log("BckImage.match.fileName:" + BckImage.match.fileName)
        item.data.advancedSettings.assets.main.name = BckImage.match.fileName
        updateItem(item, previewMode)
        //getTempImagePath(BckImage.match.fileName);
      }
      //let itemsArray = assetRequirements.data.find(obj => (obj.title.indexOf('item')));
      for (let index = 0; index < assetRequirements.data.length; index++) {
        const element = assetRequirements.data[index]
        if (element.match !== undefined && element.title.indexOf("item") >= 0) {
          console.log("found: " + element.title, element.index)
          if (
            item.data.advancedSettings.listItems[element.index].assets ===
            undefined
          ) {
            item.data.advancedSettings.listItems[
              element.index
            ].advancedSettings.assets.main = { name: "" }
          }
          item.data.advancedSettings.listItems[
            element.index
          ].advancedSettings.assets.main.name = element.match.fileName
        }
      }

      setOpenPresetAssetBrower(false)
      setOpenAssetBrower(false)
    } else {
      console.log("handleclose", item)
      setOpenPresetAssetBrower(false)
      setOpenAssetBrower(false)
    }
  }

  const selectionTool = (
    <React.Fragment>
      <Grid container alignItems="center" justify="space-evenly"></Grid>
    </React.Fragment>
  )

  ///return the three layers
  return selectionTool
}

export const SegmentListAdvancedSettingsForm = (props) => {
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    device,
    portrait,
    handleAssetsViewerOpen,
  } = itemContext
  const { advancedSettings } = item.data
  const assetRequirements = {
    presentMatches: false,
    data: [
      {
        type: "jpg_png",
        width:
          item.data.orientation === "Portrait" ||
          item.data.orientation === undefined
            ? device.data.screenWidth
            : device.data.screenHeight,
        height:
          item.data.orientation === "Portrait" ||
          item.data.orientation === undefined
            ? device.data.screenHeight
            : device.data.screenWidth,
      },
    ],
  }
  if (item.data.orientation === "Landscape") {
    advancedSettings.barMode = "B"
  }
  console.log("assetRequirements", assetRequirements, item.data.orientation)
  useEffect(() => {
    console.log("snapshots updated now", item)
    // setInteractiveLayer(createInteractiveLayer());
    //setCount(count+1);
    console.log("item update")
    //updateItem(item,previewMode)
  }, [item.data.advancedSettings.barMode])
  const changeSize = (size) => {
    console.log(size)
    advancedSettings.size = size
    if (size > advancedSettings.listItems.length) {
      const startCount = advancedSettings.listItems.length
      const diff = size - advancedSettings.listItems.length
      for (let index = 0; index < diff; index++) {
        advancedSettings.listItems.push({
          title: "",
          index: startCount + index,
          single: false,
          A: {
            link: null,
          },
          B: {
            link: null,
          },
          C: {
            link: null,
          },
          D: {
            link: null,
          },
        })
      }
    }
    updateItem(item, previewMode)
  }
  const changeListCount = (listCount) => {
    console.log(listCount)
    if (listCount > advancedSettings.listItems.length) {
      const startCount = advancedSettings.listItems.length
      const diff = listCount - advancedSettings.listItems.length
      for (let index = 0; index < diff; index++) {
        advancedSettings.listItems.push({
          title: "",
          index: startCount + index,
          single: false,
          A: {
            link: null,
          },
          B: {
            link: null,
          },
          C: {
            link: null,
          },
          D: {
            link: null,
          },
        })
      }
    } else if (listCount < advancedSettings.listItems.length) {
      advancedSettings.listItems = advancedSettings.listItems.slice(
        listCount,
        listCount - advancedSettings.listItems.length
      )
    }
    //advancedSettings.listItems = listCount;
    updateItem(item, previewMode)
  }
  const handleBarChange = (name) => {
    console.log("change: " + name)

    advancedSettings.barMode = name
    updateItem(item, previewMode)
    console.log("change: " + advancedSettings.barMode, item)
  }
  if (advancedSettings.listItems.length === 0) {
    console.log("state.listCount undefined", advancedSettings.listItems.length)
    //state.listCount = state.size;
    changeListCount(advancedSettings.size)
  }
  const listItemArray = []
  for (let index = 0; index < 25; index++) {
    if (index >= advancedSettings.size) {
      listItemArray.push(index)
    }
  }

  console.log("render list setting")

  const [slicesSettings, setSlicesSettings] = useState({
    sliceNumber: advancedSettings.size,
    listType: "",
    imageHeight: "",
  })

  const handleSliceValue = (height) => {
    console.log(
      "slicesSettings, slicesSettings.imageHeight :>> ",
      slicesSettings,
      height
    )
    let visibleSlices
    let visibleListStyle
    // eslint-disable-next-line default-case
    switch (height) {
      case 1008:
        visibleSlices = 1
        visibleListStyle = "C"
        setSlicesSettings({ ...slicesSettings, sliceNumber: 1, listType: "C" })
        break
      case 1144:
        visibleSlices = 1
        visibleListStyle = "T"
        setSlicesSettings({ ...slicesSettings, sliceNumber: 1, listType: "T" })
        break
      case 504:
        visibleSlices = 2
        visibleListStyle = "C"
        setSlicesSettings({ ...slicesSettings, sliceNumber: 2, listType: "C" })
        break
      case 572:
        visibleSlices = 2
        visibleListStyle = "T"
        setSlicesSettings({ ...slicesSettings, sliceNumber: 2, listType: "T" })
        break
      case 336:
        visibleSlices = 3
        visibleListStyle = "C"
        setSlicesSettings({ ...slicesSettings, sliceNumber: 3, listType: "C" })
        break
      case 381:
        visibleSlices = 3
        visibleListStyle = "T"
        setSlicesSettings({ ...slicesSettings, sliceNumber: 3, listType: "T" })
        break
      case 252:
        visibleSlices = 4
        visibleListStyle = "C"
        setSlicesSettings({ ...slicesSettings, sliceNumber: 4, listType: "C" })
        break
      case 286:
        visibleSlices = 4
        visibleListStyle = "T"
        setSlicesSettings({ ...slicesSettings, sliceNumber: 4, listType: "T" })
        break
      case 202:
        visibleSlices = 5
        visibleListStyle = "C"
        setSlicesSettings({ ...slicesSettings, sliceNumber: 5, listType: "C" })
        break
      case 229:
        visibleSlices = 5
        visibleListStyle = "T"
        setSlicesSettings({ ...slicesSettings, sliceNumber: 5, listType: "T" })
        break
      case 168:
        visibleSlices = 6
        visibleListStyle = "C"
        setSlicesSettings({ ...slicesSettings, sliceNumber: 6, listType: "C" })
        break
      case 190:
        visibleSlices = 6
        visibleListStyle = "T"
        setSlicesSettings({ ...slicesSettings, sliceNumber: 6, listType: "T" })
        break
      case 144:
        visibleSlices = 7
        visibleListStyle = "C"
        setSlicesSettings({ ...slicesSettings, sliceNumber: 7, listType: "C" })
        break
      case 163:
        visibleSlices = 7
        visibleListStyle = "T"
        setSlicesSettings({ ...slicesSettings, sliceNumber: 7, listType: "T" })
        break
      case 126:
        visibleSlices = 8
        visibleListStyle = "C"
        setSlicesSettings({ ...slicesSettings, sliceNumber: 8, listType: "C" })
        break
      case 143:
        visibleSlices = 8
        visibleListStyle = "T"
        setSlicesSettings({ ...slicesSettings, sliceNumber: 8, listType: "T" })
        break
      case 112:
        visibleSlices = 9
        visibleListStyle = "C"
        setSlicesSettings({ ...slicesSettings, sliceNumber: 9, listType: "C" })
        break
      case 127:
        visibleSlices = 9
        visibleListStyle = "T"
        setSlicesSettings({ ...slicesSettings, sliceNumber: 9, listType: "T" })
        break
      default: {
        visibleSlices = 6
      }
    }
    handleBarChange(visibleListStyle)
    changeSize(visibleSlices)
    return slicesSettings.sliceNumber
  }

  return (
    <Grid style={{ width: "100%" }}>
      {item.data.orientation !== "Landscape" ? (
        <Grid>
          <FormControlLabel
            control={
              <Checkbox
                checked={advancedSettings.barMode === "T"}
                onChange={(e) => handleBarChange("T")}
                value="checkedA"
                color="primary"
              />
            }
            label="Top bar"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={advancedSettings.barMode === "B"}
                onChange={(e) => handleBarChange("B")}
                value="checkedB"
                color="primary"
              />
            }
            label="Bottom bar"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={advancedSettings.barMode === "C"}
                onChange={(e) => handleBarChange("C")}
                value="checkedC"
                color="primary"
              />
            }
            label="Combo bar"
          />
        </Grid>
      ) : null}
      {portrait ? (
        <Grid>
          <FormControlLabel
            control={
              <Checkbox
                checked={advancedSettings.reverse}
                onChange={(value) => {
                  advancedSettings.reverse = !advancedSettings.reverse
                  updateItem(item, previewMode)
                }}
                value="checkedReverse"
                color="primary"
              />
            }
            label="Reverse list"
          />
        </Grid>
      ) : null}
      <Grid style={{ width: "100%" }}>
        <DropDownMenu
          items={[1, 2, 3, 4, 5, 6, 7, 8, 9]}
          value={slicesSettings.sliceNumber}
          label={"Visible slices"}
          changeTitle={changeSize}
          type="visibleSlices"
        />
        {/* <FormControl variant="outlined" className={classes.formControl} style={style}>
          <InputLabel ref={inputLabel} id={"Visible slices input"}>
          Visible slices
          </InputLabel>
          <Select
            labelId={"Visible slices label"}
            id={label + "id"}
            value={slicesSettings.sliceNumber}
            onChange={handleChange}
            labelWidth={labelWidth}
          >
            {listItems}
          </Select>
        </FormControl> */}
        {/* <DropDownMenu
          items={listItemArray}
          value={advancedSettings.listItems.length}
          label={"List count"}
          changeTitle={changeListCount}
        /> */}
      </Grid>

      <AutoAssignButton
        onSelection={(val) => {
          console.log("val:", val)
          let tempCode
          if (val === "keypad") {
            tempCode = ("000" + item.data.code).slice(-4)
          } else {
            tempCode = item.data.reference
          }
          if (val === "reference" || val === "keypad") {
            const assetPresetRequirements = {
              presentMatches: true,
              data: [
                {
                  type: "jpg",
                  title: "Background image",
                  code: tempCode + "_00",
                },
                {
                  type: "mp3, aac",
                  title: "Background Audio",
                  code: tempCode + "_00",
                },
              ],
            }
            for (let index = 0; index < 100; index++) {
              //const element = array[index];
              assetPresetRequirements.data.push({
                type: "jpg_png_jpeg_mp3_aac",
                title: "List item " + (index + 1),
                index: index,
                code: tempCode + "_" + ("00" + (index + 1)).slice(-2),
              })
            }
            console.log("assetPresetRequirements", assetPresetRequirements)
            handleAssetsViewerOpen(
              assetPresetRequirements,
              item.data.advancedSettings.assets.main,
              async (meta) => {
                console.log("asset selected", meta)
                if (meta.data[2]) {
                  const valueToCheck = meta.data[2]
                  await AssetDatabase.getAsset(
                    valueToCheck.match.dropboxInfo.path_lower
                  )
                    .then(async (res) => {
                      const blobData = await (await fetch(res.link)).blob()

                      const reader = new FileReader()
                      reader.readAsDataURL(blobData) // converts the blob to base64 and calls onload
                      reader.onload = function (evt) {
                        const image = new Image()
                        image.src = reader.result
                        image.onload = function () {
                          setSlicesSettings({
                            ...slicesSettings,
                            imageHeight: image.height,
                          })
                          handleSliceValue(image.height)
                        }
                      }
                    })
                    .catch(function (error) {
                      console.log(error)
                    })
                }
                // background
                let bck = assetPresetRequirements.data.find(
                  (obj) => obj.title === "Background image"
                )
                let bckAudio = assetPresetRequirements.data.find(
                  (obj) => obj.title === "Background Audio"
                )
                if (bck?.match) {
                  console.log("asset selected progress background", bck.match)
                  const { dropboxInfo } = bck.match
                  item.data.advancedSettings.assets.main = {
                    meta: { path_lower: dropboxInfo.path_lower },
                    name: dropboxInfo.name,
                  }
                }
                if (bckAudio?.match) {
                  console.log(
                    "asset selected progress background",
                    bckAudio.match
                  )
                  const { dropboxInfo } = bckAudio.match
                  item.data.advancedSettings.assets.audio = {
                    meta: { path_lower: dropboxInfo.path_lower },
                    name: dropboxInfo.name,
                  }
                }
                for (let index = 0; index < 100; index++) {
                  let bck = assetPresetRequirements.data.find(
                    (obj) => obj.title === "List item " + (index + 1)
                  )
                  if (bck?.match) {
                    const { dropboxInfo } = bck.match
                    if (item.data.advancedSettings.listItems.length > index) {
                      const listItem =
                        item.data.advancedSettings.listItems[index]
                      listItem.assets = {
                        meta: { path_lower: dropboxInfo.path_lower },
                        name: dropboxInfo.name,
                      }
                      console.log(
                        "asset selected progress list item",
                        bck.match,
                        listItem,
                        item
                      )
                    } else {
                      item.data.advancedSettings.listItems.push({
                        single: false,
                        index: index,
                        A: {
                          link: null,
                        },
                        B: {
                          link: null,
                        },
                        C: {
                          link: null,
                        },
                        D: {
                          link: null,
                        },
                        title: "",
                        assets: {
                          meta: { path_lower: dropboxInfo.path_lower },
                          name: dropboxInfo.name,
                        },
                      })
                      console.log(
                        "asset selected create new list item",
                        bck.match
                      )
                    }
                  }
                }
                updateItem(item)
              },
              previewMode
            )
          }
        }}
      />
      <Grid item style={{ paddingBottom: 10 }}>
        <Typography variant="h4" component="h2" style={{ paddingTop: 10 }}>
          Assets &amp; Slices
        </Typography>
      </Grid>
      <Grid item style={{ width: "40%" }}>
        <SelectAsset
          title={"Background"}
          assetRequirements={assetRequirements}
          asset={item.data.advancedSettings.assets.main}
          onSelect={(meta, imgName) => {
            console.log("setting -> src --> ", meta)
            console.log("setting -> name --> ", imgName)
            item.data.advancedSettings.assets.main = {
              meta: meta,
              name: imgName,
            }
            //getTempImagePath(imgName)
            updateItem(item, previewMode)
          }}
        />
        <SelectAsset
          title={"Audio"}
          assetRequirements={{
            presentMatches: false,
            data: [
              {
                type: "mp3",
              },
            ],
          }}
          asset={item.data.advancedSettings.assets.audio}
          onSelect={(meta, audioName) => {
            console.log("setting -> src --> ", meta)
            console.log("setting -> name --> ", audioName)
            item.data.advancedSettings.assets.audio = {
              meta: meta,
              name: audioName,
            }
            //getTempImagePath(imgName)
            updateItem(item, previewMode)
          }}
        />
      </Grid>
      <SegmentedListItemEditor />
    </Grid>
  )
}

export const SegmentListBuildBase = (component, buildId) => {
  const getFourDigitCode = (code) => {
    return ("000" + code).slice(-4)
  }
  const generateListFileName = (code) => {
    const orientation = component.data.portrait === false ? "F" : "P"
    const { advancedSettings } = component.data
    const ext = component.data.advancedSettings.assets.main.name.substr(
      component.data.advancedSettings.assets.main.name.lastIndexOf(".") + 1
    )
    return (
      code +
      "_00_" +
      code +
      "_L_" +
      orientation +
      "_" +
      advancedSettings.barMode +
      "_" +
      advancedSettings.size +
      "." +
      ext
    )
  }
  let filename = generateListFileName(getFourDigitCode(component.code))
  let ent = {
    from_path: `/${component.data.clientId}/${component.data.projectId}/assets/${component.data.advancedSettings.assets.main.name}`,
    to_path: `/${component.data.clientId}/${component.data.projectId}/builds/${buildId}/${filename}`,
  }
  return ent
}
export const SegmentListBuildAssets = (
  component,
  ComponentDetailData,
  buildId
) => {
  const getFourDigitCode = (code) => {
    return ("000" + code).slice(-4)
  }
  const getTwoDigitCode = (code) => {
    return ("00" + code).slice(-2)
  }
  const generateListItemFileName = (code, item) => {
    const orientation = component.data.portrait === false ? "F" : "P"
    const { advancedSettings } = component.data
    const link = ComponentDetailData.find((obj) => obj.id === item.link)
    const ext = item.advancedSettings.assets.main.name.substr(
      item.advancedSettings.assets.main.name.lastIndexOf(".") + 1
    )
    return (
      code +
      "_" +
      getTwoDigitCode(item.index) +
      "_" +
      getFourDigitCode(link.code) +
      "." +
      ext
    )
  }
  const { advancedSettings } = component.data
  let ents = []
  advancedSettings.listItems.forEach((item) => {
    console.log("checking list item")
    if (item.assets !== undefined) {
      console.log("adding list item")
      let filename = generateListItemFileName(
        getFourDigitCode(component.code),
        item
      )
      let ent = {
        from_path: `/${component.data.clientId}/${component.data.projectId}/assets/${item.assets.name}`,
        to_path: `/${component.data.clientId}/${component.data.projectId}/builds/${buildId}/${filename}`,
      }
      ents.push(ent)
    }
  })

  return ents
}
/// Menu data which consists of txt files
export const SegmentListBuildData = async (
  component,
  ComponentDetailData,
  buildId
) => {
  const getFourDigitCode = (code) => {
    return ("000" + code).slice(-4)
  }
  const getTwoDigitCode = (code) => {
    return ("00" + code).slice(-2)
  }

  let objs = []
  if (component.data.buttons !== undefined) {
    for (let index = 0; index < component.data.buttons.length; index++) {
      const button = component.data.buttons[index]
      for (
        let blockIndex = 0;
        blockIndex < button.selectedBlocks.length;
        blockIndex++
      ) {
        let filename = ""
        let fourDigit = getFourDigitCode(component.code)
        const block = button.selectedBlocks[blockIndex]

        const link = ComponentDetailData.find((obj) => obj.id === button.link)

        filename =
          "i" +
          fourDigit +
          "_" +
          getTwoDigitCode(block) +
          "_" +
          getFourDigitCode(link.code) +
          ".jpg"
        let content = `if it ain't much, it ain't Dutch ;-)`
        let path = `/${component.data.clientId}/${component.data.projectId}/builds/${buildId}/${filename}`
        console.log("sending ---> ", content)
        let result = await AssetDatabase.filesUploadSession(content)
        console.log("commiting ---> ", result)
        let _obj = {
          cursor: {
            session_id: result.session_id,
            offset: content.length,
          },
          commit: {
            path: path,
            mode: "overwrite",
          },
        }
        objs.push(_obj)
      }
    }
  }

  return objs
}
