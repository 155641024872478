/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { ItemContext } from "../../ComponentLoader"
import Slider from "@material-ui/core/Slider"
import VolumeUp from "@material-ui/icons/VolumeUp"
import VolumeDown from "@material-ui/icons/VolumeDown"
import Dialpad from "@material-ui/icons/Dialpad"
import { isIOS, isAndroid } from "react-device-detect"
import DefaultSettings from "../../../../defaultSettings/settings"
import FontSettings from "../../../../data/FontSettings"

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#B9D949",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  formControl: {
    margin: theme.spacing(2),
  },
}))

export default function PlayerController(props) {
  const itemContext = useContext(ItemContext)
  let {
    item,
    previewMode,
    siblings,
    updateItem,
    openingComponentSelector,
    previousItem,
    portrait,
    TourPlayer,
    buttonPressed,
    transcriptFontSize,
    setTranscriptFontSize,
  } = itemContext

  const fontColor = FontSettings.filter(
    (font) => item.tourDetails.data.fontSettings === font.value
  )[0]

  const { playedSeconds, src, issueLoading } = props
  const [showTranscript, setShowTranscript] = useState(false)
  // const [fontSize, setFontSize] = useState(2)
  //console.log('playedSeconds:',playedSeconds, src);
  let three60 = props.three60 !== undefined ? props.three60 : false
  let bckPauseImage = require("../../../../images/pause.png")
  let bckPlayImage = require("../../../../images/play.png")
  let bckPlayCenterImage = require("../../../../images/center_play.png")
  let bckLeftArrowImage = require("../../../../images/left_arrow.png")
  let bckRightArrowImage = require("../../../../images/right_arrow.png")
  let transcriptButtonImage = require("../../../../images/transcriptButton.png")
  let transcriptMinusButton = require("../../../../images/transcriptMinusButton.png")
  let transcriptAA = require("../../../../images/transcriptAA.png")
  let transcriptPlusButton = require("../../../../images/transcriptPlusButton.png")
  let transcriptCloseButton = require("../../../../images/transcriptCloseButton.png")
  const [timer, setTimer] = React.useState(null)
  const [disablePlayerControls, setDisablePlayerControls] = React.useState(
    props.disablePlayerControls || false
  )

  const [state, setState] = React.useState({ seeking: false, played: 0 })
  let playing = true
  let played = 0
  let playedSecond = 12
  let volume = DefaultSettings.volume
  const onVolumeChange = (volume) => {
    //console.log('new volume:',volume-0.1<0?0:volume-0.1);
    //props.setVolume(volume)
    DefaultSettings.volume = volume
  }
  const handleVolumeSliderChange = (event, newValue) => {
    //console.log('new volume:',newValue/100);
    //props.setVolume(parseFloat(newValue/100))
    DefaultSettings.volume = parseFloat(newValue / 100)
  }

  const onPausePlay = () => {
    props.handlePlayPause()
  }
  const onPrevious = () => {
    if (props.toPreviousItem !== undefined) {
      console.log("on Previous event Triggered :>> ", props)
      props.toPreviousItem()
    } else {
      // if (previewMode === false) {
      //   window.alert("The previous function will only work in preview mode")
      //   return
      // }
      console.log("previousItem: ", previousItem)
      updateItem(itemContext.previousItem, previewMode, "Blocks onblockclick")
    }
  }
  //INVESTIGATE

  useEffect(() => {
    if (props.onClickForPlayer) {
      showControlsWithTimer()
      console.log("player should show controls")
      props.setOnClickForPlayer(false)
    }
  }, [props.onClickForPlayer])
  console.log("player:", props.playedSeconds, props.duration)
  const onNext = () => {
    //props.gotoNextItem();
    if (props.toNextItem !== undefined) {
      props.toNextItem()
    } else {
      // if (previewMode === false && DefaultSettings.displayMode === "CMS") {
      //   window.alert("The next function will only work in preview mode")
      //   return
      // }

      console.log("onNext:", buttonPressed)
      if (buttonPressed !== null) {
        if (buttonPressed.returnLink) {
          console.log("previousItem: ", itemContext.previousItem)
          updateItem(
            itemContext.previousItem,
            previewMode,
            "Blocks onblockclick"
          )
          return
        }
      }
      if (
        item.data.advancedSettings.link === null ||
        item.data.advancedSettings.link === undefined
      ) {
        if (item.data.advancedSettings.toKeypad) {
          let keypadItem = DefaultSettings.keypadItem
          keypadItem.tourDetails = item.tourDetails
          keypadItem.clientId = item.clientId
          keypadItem.projectId = item.projectId
          keypadItem.projectDetails = item.projectDetails
          updateItem(keypadItem, previewMode, "update to keypad")
        } else {
          console.log("previousItem: ", itemContext.previousItem)
          props.toPreviousItem()
          updateItem(
            itemContext.previousItem,
            previewMode,
            "Blocks onblockclick"
          )
        }
        return
      } else {
        if (item.data.advancedSettings.link === "toKeypad") {
          let keypadItem = DefaultSettings.keypadItem
          keypadItem.tourDetails = item.tourDetails
          keypadItem.clientId = item.clientId
          keypadItem.projectId = item.projectId
          keypadItem.projectDetails = item.projectDetails
          updateItem(keypadItem, previewMode, "update to keypad")
        } else {
          let linkItem = null
          siblings.forEach((element) => {
            if (element.id === item.data.advancedSettings.link) {
              linkItem = element
            }
          })
          updateItem(linkItem, previewMode, "Blocks onblockclick")
          return
        }
      }
    }
  }
  useEffect(() => {
    showControlsWithTimer()
    // if (props.playing === false) {
    //   setShowControls(true)
    //   clearTimeout(timer)
    // }
  }, [props.playing])
  const handleSeekMouseDown = (e) => {
    setState((prevState) => {
      return { ...prevState, seeking: true }
    })
  }
  const handleSeekMouseUp = (event) => {
    //this.setState({ seeking: false })
    console.log("handleSeekMouseUp--->")

    setState({ ...state, seeking: false })
    props.seekTo(parseFloat(state.played))
  }
  const handleSliderChange = (event, newValue) => {
    if (three60) {
      const videoPlayer = props.seekTo()
      videoPlayer.currentTime = event.target.value
    }
    console.log(
      "props overall inside handleSliderChange :>> ",
      props,
      newValue,
      event.target.value
    )
    console.log("new value--->", parseFloat(newValue / 100))
    console.log("event :>> ", event)
    console.log("event.target.value :>> ", event?.target?.value)
    // alert(newValue)
    // setTimeout(() => {
    // setState({ ...state, played: parseFloat(newValue) })
    setState({
      ...state,
      played: parseFloat(event.target.value || state.played),
    })

    // }, 400);
    props.seekTo(
      parseFloat(event.target?.value ? event.target.value : state.played)
    )
  }
  const [showControls, setShowControls] = useState(true)
  let barHeight = portrait
    ? (TourPlayer.height / 100) * 10.9375
    : 1.5 * ((TourPlayer.height / 100) * 10.9375)
  if (barHeight > 100) {
    barHeight = 100
  }
  const showControlsWithTimer = useCallback(() => {
    setShowControls(true)
    if (timer !== null) {
      clearTimeout(timer)
    }
    setTimer(
      setTimeout(() => {
        console.log("timer complete")
        setShowControls(false)
        clearTimeout(timer)
      }, 5000)
    )
  })
  // if (timer === null && props.playing === true) {
  //   setTimer(
  //     setTimeout(() => {
  //       console.log("timer complete")
  //       setShowControls(false)
  //       clearTimeout(timer)
  //     }, 5000)
  //   )
  // }
  let transcriptView = (
    <Grid
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        width: TourPlayer.width,
        height: TourPlayer.height,
        backgroundColor:
          item.data.advancedSettings.transcriptBgColor || "#000000",
        zIndex: 100,
        flex: 1,
      }}
    >
      <Grid
        container
        style={{
          flexDirection: "row",
          height: TourPlayer.height / 10,
          flex: 1,
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
        alignContent={"center"}
      >
        <Grid item style={{ width: "25%", justifyItems: "flex-start" }}></Grid>
        <Grid
          container
          item
          style={{
            width: "50%",
            flexDirection: "row",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <div
            onClick={(e) => {
              //setShowTranscript(false)
              // if (fontSize > 1) {
              //   setFontSize((fontSize) => fontSize - 1)
              // }
              if (transcriptFontSize > 1) {
                setTranscriptFontSize(transcriptFontSize - 1)
              }
            }}
            style={{
              backgroundImage: `url(${transcriptMinusButton})`,
              backgroundSize: "cover",
              width: (barHeight / 2 / 134) * 170,
              height: barHeight / 2,
              cursor: "pointer",
            }}
          ></div>
          <div
            style={{
              backgroundImage: `url(${transcriptAA})`,
              backgroundSize: "cover",
              width: (barHeight / 2 / 134) * 174,
              height: barHeight / 2,
              cursor: "pointer",
            }}
          ></div>
          <div
            onClick={(e) => {
              //setShowTranscript(false)
              // if (fontSize < 3) {
              //   setFontSize((fontSize) => fontSize + 1)
              // }
              if (transcriptFontSize < 3) {
                setTranscriptFontSize(transcriptFontSize + 1)
              }
            }}
            style={{
              backgroundImage: `url(${transcriptPlusButton})`,
              backgroundSize: "cover",
              width: (barHeight / 2 / 134) * 172,
              height: barHeight / 2,
              cursor: "pointer",
            }}
          ></div>
        </Grid>
        <Grid
          container
          style={{
            width: "25%",
            alignContent: "center",
            justifyContent: "flex-end",
            paddingRight: "5%",
          }}
        >
          <Grid
            item
            onClick={(e) => {
              setShowTranscript(false)
            }}
            style={{
              backgroundImage: `url(${transcriptCloseButton})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              width: barHeight / 2,
              height: barHeight / 2,
              cursor: "pointer",
            }}
          ></Grid>
        </Grid>
      </Grid>
      <Grid
        style={{
          flex: 10,
          overflow: "auto",
          height: 9 * (TourPlayer.height / 10),
          padding: 5,
          userSelect: "none",
        }}
      >
        {fontColor ? (
          <Fragment>
            {
              <p
                style={{
                  color: fontColor.hexCode,
                  // fontSize: 15 + fontSize * 3,
                  fontSize: 15 + transcriptFontSize * 3,
                  whiteSpace: "pre-line",
                  textSizeAdjust: "auto",
                  textAlign: "left",
                  wordWrap: "normal",
                  textOverflow: "ellipsis",
                  fontFamily: "Roboto",
                }}
              >
                {item.data.advancedSettings.transcriptText}
              </p>
            }
          </Fragment>
        ) : (
          <p
            style={{
              color: "white",
              // fontSize: 15 + fontSize * 3,
              fontSize: 15 + transcriptFontSize * 3,
              whiteSpace: "pre-line",
              textSizeAdjust: "auto",
              textAlign: "left",
              wordWrap: "normal",
              textOverflow: "ellipsis",
              fontFamily: "Roboto",
            }}
          >
            {item.data.advancedSettings.transcriptText}
          </p>
        )}
      </Grid>
    </Grid>
  )

  let playerControlsView = (
    <Grid>
      <Grid
        container
        onMouseDown={showControlsWithTimer}
        onMouseOver={(showControls) => {
          showControlsWithTimer()
        }}
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          width: TourPlayer.width,
          height: barHeight,
          zIndex: 200,
        }}
      >
        {!isIOS ||
        item.data.showKeyboard ||
        item.data.advancedSettings?.transcript ? (
          <Grid
            style={{
              display: showControls ? "block" : "none",
              position: "absolute",
              left: 0,
              top: 0,
              bottom: 0,
              right: 0,
              width: TourPlayer.width,
              height: barHeight,
              zIndex: 100,
            }}
          >
            <div
              style={{
                postion: "relative",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "block",
                outline: "none",
                width: TourPlayer.width,
                height: barHeight,
                zIndex: 100,
              }}
            >
              <Grid
                container
                style={{
                  postion: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  width: TourPlayer.width,
                  height: barHeight,
                  justifyContent: "flex-end",
                  zIndex: 100,
                }}
              >
                <Grid
                  item
                  style={{
                    width: TourPlayer.width,
                    backgroundColor: "#000000cc",
                    justifyContent: "space-between",
                    padding:
                      (TourPlayer.height / 100) *
                      (portrait ? 10.9375 : 15.9375) *
                      0.2,
                  }}
                >
                  <Grid container style={{ justifyContent: "space-between" }}>
                    <Grid
                      container
                      spacing={1}
                      alignItems="center"
                      style={{
                        justifyContent: "center",
                        // justifyContent:
                        //   isIOS || isAndroid ? "space-between" : "center",
                      }}
                    >
                      {item.data.showKeyboard ? (
                        <Grid item>
                          <Dialpad
                            fontSize={"large"}
                            onClick={(e) => {
                              let keypadItem = DefaultSettings.keypadItem
                              keypadItem.clientId = item.clientId
                              keypadItem.projectId = item.projectId
                              keypadItem.tourDetails = item.tourDetails
                              keypadItem.projectDetails = item.projectDetails
                              updateItem(
                                keypadItem,
                                previewMode,
                                "keypad selected"
                              )
                            }}
                            style={{
                              color: "white",
                              cursor: "pointer",
                              fontSize: barHeight / 2,
                            }}
                          />
                        </Grid>
                      ) : null}
                      {!isIOS && !isAndroid ? (
                        <React.Fragment>
                          <Grid item>
                            <VolumeDown
                              onClick={(e) => {
                                onVolumeChange(
                                  DefaultSettings.volume - 0.1 < 0
                                    ? 0
                                    : DefaultSettings.volume - 0.1
                                )
                              }}
                              style={{
                                color: "white",
                                cursor: "pointer",
                                fontSize: barHeight / 2,
                              }}
                            />
                          </Grid>
                          <Grid item xs>
                            <Slider
                              value={
                                typeof DefaultSettings.volume === "number"
                                  ? DefaultSettings.volume * 100
                                  : 0
                              }
                              onChange={handleVolumeSliderChange}
                              aria-labelledby="input-slider"
                              style={{ color: DefaultSettings.sliderColour }}
                            />
                          </Grid>
                          <Grid item>
                            <VolumeUp
                              onClick={(e) => {
                                console.log(DefaultSettings.volume)
                                onVolumeChange(
                                  DefaultSettings.volume + 0.1 > 1
                                    ? 1
                                    : DefaultSettings.volume + 0.1
                                )
                              }}
                              style={{
                                color: "white",
                                cursor: "pointer",
                                fontSize: barHeight / 2,
                              }}
                            />
                          </Grid>
                        </React.Fragment>
                      ) : null}

                      {item.data.advancedSettings.transcript ? (
                        <Grid item style={{ marginLeft: "auto" }}>
                          {/* <TextSnippetIcon
                            fontSize={"large"}
                            
                            style={{ color: "white", cursor: "pointer",fontSize:barHeight/2 }}
                          /> */}
                          <div
                            onClick={(e) => {
                              setShowTranscript(true)
                            }}
                            style={{
                              backgroundImage: `url(${transcriptButtonImage})`,
                              backgroundSize: "cover",
                              width: barHeight / 2,
                              height: barHeight / 2,
                              cursor: "pointer",
                            }}
                          ></div>
                        </Grid>
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
        ) : null}
      </Grid>

      {three60 || true ? (
        <Grid
          container
          style={{
            display: !props.playing ? "block" : "none",
            position: "absolute",
            left: TourPlayer.width / 2 - barHeight / 2,
            top: TourPlayer.height / 2 - barHeight / 2,
            width: barHeight,
            height: barHeight,
            zIndex: 100,
            transform: "translate3d(0, 0, 200px)",
          }}
        >
          <Grid
            style={{
              display: "block",
              position: "absolute",
              width: barHeight,
              height: barHeight,
            }}
          >
            <div
              style={{
                postion: "relative",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "block",
                outline: "none",
                width: barHeight,
                height: barHeight,
              }}
            >
              <Grid
                container
                justify="center"
                style={{
                  postion: "absolute",
                  backgroundColor: "#0000",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  width: "100%",
                  height: barHeight,
                }}
              >
                {!props.playing ? (
                  <div
                    onClick={onPausePlay}
                    style={{
                      backgroundImage: `url(${bckPlayCenterImage})`,
                      backgroundSize: "cover",
                      width: barHeight,
                      height: barHeight,
                      cursor: "pointer",
                    }}
                  ></div>
                ) : null}
              </Grid>
            </div>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          //onClick={showControlsWithTimer}
          onMouseDown={showControlsWithTimer}
          onMouseOver={(showControls) => {
            showControlsWithTimer()
          }}
          // onMouseOut={() => {
          //   setShowControls(false)
          // }}
          style={{
            position: "absolute",
            left: 0,
            top: barHeight,
            width: TourPlayer.width,
            height: TourPlayer.height - 2 * barHeight,
            zIndex: 100,
          }}
        >
          <Grid
            style={{
              display: showControls ? "block" : "none",
              position: "absolute",
              top: (TourPlayer.height - 2 * barHeight) / 2 - barHeight / 2,
              width: TourPlayer.width,
              height: barHeight,
            }}
          >
            <div
              style={{
                postion: "relative",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "block",
                outline: "none",
                width: TourPlayer.width,
                height: barHeight,
              }}
            >
              <Grid
                container
                justify="center"
                style={{
                  postion: "absolute",
                  backgroundColor: "#0000",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  width: "100%",
                  height: barHeight,
                }}
              >
                {!props.playing &&
                (issueLoading === false || issueLoading === undefined) &&
                !disablePlayerControls ? (
                  <div
                    onClick={onPausePlay}
                    style={{
                      backgroundImage: `url(${bckPlayCenterImage})`,
                      backgroundSize: "cover",
                      width: barHeight,
                      height: barHeight,
                      cursor: "pointer",
                    }}
                  ></div>
                ) : null}
              </Grid>
            </div>
          </Grid>
        </Grid>
      )}

      <Grid
        container
        //onClick={showControlsWithTimer}
        onMouseDown={showControlsWithTimer}
        onMouseOver={(showControls) => {
          showControlsWithTimer()
        }}
        // onMouseOut={() => {
        //   setShowControls(false)
        // }}
        style={{
          position: "absolute",
          right: 0,
          left: 0,
          top: TourPlayer.height - barHeight,
          bottom: TourPlayer.height,
          width: TourPlayer.width,
          height: barHeight,
          zIndex: 100,
          transform: "translate3d(0, 0, 100px)",
        }}
      >
        <Grid
          style={{
            display: showControls ? "block" : "none",
            position: "absolute",
            left: 0,
            top: 0,
            bottom: 0,
            right: 0,
            width: TourPlayer.width,
            height: barHeight,
          }}
        >
          <div
            style={{
              postion: "relative",
              display: "block",
              outline: "none",
              width: TourPlayer.width,
              height: barHeight,
            }}
          >
            <Grid
              container
              style={{
                postion: "absolute",
                backgroundColor: "#000000cc",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                width: "100%",
                height: barHeight,
              }}
            >
              <Grid
                container
                alignItems="center"
                justify="center"
                alignContent="center"
              >
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justify="center"
                  alignContent="center"
                >
                  <Grid item>
                    <div
                      onClick={onPrevious}
                      style={{
                        backgroundImage: `url(${bckLeftArrowImage})`,
                        backgroundSize: "cover",
                        width: barHeight / 2.4,
                        height: barHeight / 2.4,
                        cursor: "pointer",
                      }}
                    ></div>
                  </Grid>
                  {(issueLoading === false || issueLoading === undefined) &&
                  !disablePlayerControls ? (
                    <React.Fragment>
                      <Grid item>
                        <div
                          onClick={onPausePlay}
                          style={{
                            backgroundImage: props.playing
                              ? `url(${bckPauseImage})`
                              : `url(${bckPlayImage})`,
                            backgroundSize: "cover",
                            width: barHeight / 2,
                            height: barHeight / 2,
                            cursor: "pointer",
                          }}
                        ></div>
                      </Grid>
                      <Grid item xs>
                        {
                          // Slider default
                          <input
                            type="range"
                            min={0}
                            max={props.duration}
                            step="0.25"
                            value={props.playedSeconds}
                            // onMouseDown={handleSeekMouseDown}
                            onChange={handleSliderChange}
                            className="range_slider"
                            style={{ padding: "0px", height: barHeight / 2 }}
                            // onMouseUp={handleSeekMouseUp}
                          />
                        }
                        {/* <Slider

                      // value={(props.playedSeconds / props.duration) * 100}
                      // value={state.played}
                      value={state.played}
                      onChange={handleSliderChange}
                      onMouseDown={handleSeekMouseDown}
                      onTouchStart={handleSeekMouseDown}
                      onMouseUp={handleSeekMouseUp}
                      onTouchEnd={handleSeekMouseUp}
                      aria-labelledby="input-slider"
                      max={props.duration}
                      min={0}
                      style={{ color: DefaultSettings.sliderColour }}
                    /> */}
                        {/* <Slider
                      draggable="true"
                      onDragEnd={(e) => console.log('e inside event :>> ', e)}
                      size="small"
                      // defaultValue={70}
                      aria-label="Small"
                      value={(props.playedSeconds / props.duration) * 100}
                      valueLabelDisplay="auto"
                      // onChange={(event, newValue) => handleSliderChange(event, newValue)}
                      // onMouseUp={handleSeekMouseUp}
                      // onTouchEnd={handleSeekMouseUp}
                    /> */}
                      </Grid>
                      <Grid item>
                        {props.duration !== 0 &&
                        props.playedSeconds !== props.duration ? (
                          <div style={{ color: "white" }}>
                            -
                            {format(
                              props.duration -
                                (props.playedSeconds > props.duration
                                  ? props.duration
                                  : props.playedSeconds)
                            )}
                          </div>
                        ) : null}
                      </Grid>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Grid item>
                        <div
                          style={{
                            width: barHeight / 2,
                            height: barHeight / 2,
                          }}
                        ></div>
                      </Grid>
                      <Grid item xs></Grid>
                      <Grid item></Grid>
                    </React.Fragment>
                  )}
                  <Grid item>
                    <div
                      onClick={onNext}
                      style={{
                        backgroundImage: `url(${bckRightArrowImage})`,
                        backgroundSize: "cover",
                        width: barHeight / 2.4,
                        height: barHeight / 2.4,
                        cursor: "pointer",
                      }}
                    ></div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </Grid>
  )

  return showTranscript ? transcriptView : playerControlsView
  // return (
  //   <Grid
  //     container
  //     onMouseOver={() => {
  //       setShowControls(true)
  //     }}
  //     onMouseOut={() => {
  //       setShowControls(false)
  //     }}
  //     style={{
  //       position: "absolute",
  //       left: 0,
  //       top: 0,
  //       width: TourPlayer.width,
  //       height: TourPlayer.height,
  //     }}
  //   >
  //     <Grid
  //       style={{
  //         display: "block",
  //         position: "absolute",
  //         left: 0,
  //         top: 0,
  //         bottom: 0,
  //         right: 0,
  //         width: TourPlayer.width,
  //         height: TourPlayer.height,
  //       }}
  //     >
  //       <div
  //         style={{
  //           postion: "relative",
  //           top: 0,
  //           left: 0,
  //           right: 0,
  //           bottom: 0,
  //           display: "block",
  //           outline: "none",
  //           width: TourPlayer.width,
  //           height: TourPlayer.height,
  //         }}
  //       >
  //         <div
  //           style={{
  //             postion: "absolute",
  //             backgroundColor: "#000000cc",
  //             top: 0,
  //             left: 0,
  //             right: 0,
  //             bottom: 0,
  //             width: "100%",
  //             height: (TourPlayer.height / 100) * 10.9375,
  //           }}
  //         >
  //           <div
  //             style={{
  //               width: TourPlayer.width,
  //               padding:
  //                 (TourPlayer.height / 100) *
  //                 (portrait ? 10.9375 : 15.9375) *
  //                 0.2,
  //             }}
  //           >
  //             <Grid container>
  //               <Grid container spacing={1} alignItems="center">
  //                 <Grid item>
  //                   <VolumeDown
  //                     onClick={(e) => {
  //                       onVolumeChange(
  //                         DefaultSettings.volume - 0.1 < 0
  //                           ? 0
  //                           : DefaultSettings.volume - 0.1
  //                       )
  //                     }}
  //                     style={{ color: "white", cursor: "pointer" }}
  //                   />
  //                 </Grid>
  //                 <Grid item xs>
  //                   <Slider
  //                     value={
  //                       typeof DefaultSettings.volume === "number"
  //                         ? DefaultSettings.volume * 100
  //                         : 0
  //                     }
  //                     onChange={handleVolumeSliderChange}
  //                     aria-labelledby="input-slider"
  //                     style={{ color: "#34B4E2" }}
  //                   />
  //                 </Grid>
  //                 <Grid item>
  //                   <VolumeUp
  //                     onClick={(e) => {
  //                       console.log(DefaultSettings.volume)
  //                       onVolumeChange(
  //                         DefaultSettings.volume + 0.1 > 1
  //                           ? 1
  //                           : DefaultSettings.volume + 0.1
  //                       )
  //                     }}
  //                     style={{ color: "white", cursor: "pointer" }}
  //                   />
  //                 </Grid>
  //               </Grid>
  //             </Grid>
  //           </div>
  //         </div>

  //         <Grid
  //           container
  //           justify="center"
  //           alignItems="center"
  //           style={{
  //             postion: "absolute",
  //             backgroundColor: "#0000",
  //             top: 0,
  //             pointerEvents: "none",
  //             left: 0,
  //             right: 0,
  //             bottom: 0,
  //             width: "100%",
  //             height: (TourPlayer.height / 100) * (100 - 2 * 10.9375),
  //           }}
  //         >
  //           {!props.playing ? (
  //             <div
  //               onClick={onPausePlay}
  //               style={{
  //                 backgroundImage: `url(${bckPlayCenterImage})`,
  //                 backgroundSize: "cover",
  //                 width: ((TourPlayer.height / 100) * 10.9375) / 1,
  //                 height: ((TourPlayer.height / 100) * 10.9375) / 1,
  //               }}
  //             ></div>
  //           ) : null}
  //         </Grid>

  //         <Grid
  //           container
  //           style={{
  //             postion: "absolute",
  //             backgroundColor: "#000000cc",
  //             top: 0,
  //             left: 0,
  //             right: 0,
  //             bottom: 0,
  //             width: "100%",
  //             height: (TourPlayer.height / 100) * 10.9375,
  //           }}
  //         >
  //           <Grid
  //             container
  //             alignItems="center"
  //             justify="center"
  //             alignContent="center"
  //           >
  //             <Grid
  //               container
  //               spacing={2}
  //               alignItems="center"
  //               justify="center"
  //               alignContent="center"
  //             >
  //               <Grid alignItems="center" item>
  //                 <div
  //                   onClick={onPrevious}
  //                   style={{
  //                     backgroundImage: `url(${bckLeftArrowImage})`,
  //                     backgroundSize: "cover",
  //                     width: ((TourPlayer.height / 100) * 10.9375) / 2.4,
  //                     height: ((TourPlayer.height / 100) * 10.9375) / 2.4,
  //                   }}
  //                 ></div>
  //               </Grid>
  //               <Grid alignItems="center" item>
  //                 <div
  //                   onClick={onPausePlay}
  //                   style={{
  //                     backgroundImage: props.playing
  //                       ? `url(${bckPauseImage})`
  //                       : `url(${bckPlayImage})`,
  //                     backgroundSize: "cover",
  //                     width: ((TourPlayer.height / 100) * 10.9375) / 2,
  //                     height:
  //                       ((TourPlayer.height / 100) *
  //                         (portrait ? 10.9375 : 15.9375)) /
  //                       2,
  //                   }}
  //                 ></div>
  //               </Grid>
  //               <Grid item xs>
  //                 <Slider
  //                   value={
  //                     typeof props.played === "number" ? props.played * 100 : 0
  //                   }
  //                   onChange={handleSliderChange}
  //                   onMouseDown={handleSeekMouseDown}
  //                   onMouseUp={handleSeekMouseUp}
  //                   aria-labelledby="input-slider"
  //                   style={{ color: "#34B4E2" }}
  //                 />
  //               </Grid>
  //               <Grid item>
  //                 <div style={{ color: "white" }}>
  //                   -{format(props.duration - props.playedSeconds)}
  //                 </div>
  //               </Grid>
  //               <Grid alignItems="center" item>
  //                 <div
  //                   onClick={onNext}
  //                   style={{
  //                     backgroundImage: `url(${bckRightArrowImage})`,
  //                     backgroundSize: "cover",
  //                     width: ((TourPlayer.height / 100) * 10.9375) / 2.4,
  //                     height: ((TourPlayer.height / 100) * 10.9375) / 2.4,
  //                   }}
  //                 ></div>
  //               </Grid>
  //             </Grid>
  //           </Grid>
  //         </Grid>
  //       </div>
  //     </Grid>
  //   </Grid>
  // )
}

function format(seconds) {
  const date = new Date(seconds * 1000)
  const hh = date.getUTCHours()
  const mm = date.getUTCMinutes()
  const ss = pad(date.getUTCSeconds())
  if (hh) {
    return `${hh}:${pad(mm)}:${ss}`
  }
  return `${mm}:${ss}`
}

function pad(string) {
  return ("0" + string).slice(-2)
}
