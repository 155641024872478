import React, { useRef } from "react"
import DatabaseIndexedDb from "../../IndexedDB/DatabaseIndexedDb"
import { useParams } from "react-router-dom/cjs/react-router-dom"

export const Header = ({ primaryColour, headerUrl, cacheEnable = false }) => {
  const { previewID } = useParams()
  const timerID = useRef(null)

  const style = {
    display: "block",
    maxWidth: "100%",
    height: 40,
    objectFit: "contain",
    margin: "0 auto",
    padding: window.innerWidth < 400 ? "0px 5px" : 0,
  }

  if (cacheEnable) {
    style.cursor = "pointer"
  }

  const deleteCache = () => {
    if (cacheEnable) {
      timerID.current = setTimeout(async () => {
        const cacheWebAppData = new DatabaseIndexedDb(previewID)
        console.log("chacheWebAppData", cacheWebAppData)
        if (cacheWebAppData) {
          await cacheWebAppData.db.delete()
          window.location.reload()
        }
      }, 2000)
    }
  }

  const cancelTimer = () => {
    if (cacheEnable) {
      clearTimeout(timerID.current)
    }
  }

  return (
    <div
      style={{
        backgroundColor: primaryColour,
        padding: "10px 0px",
        width: "100%",
      }}
    >
      <img
        style={style}
        src={headerUrl}
        alt="logo"
        onContextMenu={(e) => e.preventDefault()}
        onTouchStart={deleteCache}
        onTouchEnd={cancelTimer}
        onMouseDown={deleteCache}
        onMouseUp={cancelTimer}
      />
    </div>
  )
}
