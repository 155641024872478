import React, { useState, useEffect, useContext } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import FormLabel from "@material-ui/core/FormLabel"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import RadioGroup from "@material-ui/core/RadioGroup"
import Radio from "@material-ui/core/Radio"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import { BrowserRouter, Route, Link } from "react-router-dom"
import Checkbox from "@material-ui/core/Checkbox"
import Draggable from "react-draggable"
import { ItemContext } from "../../ComponentLoader"
import SelectAsset from "../../settings/SelectAsset"
import PanTool from "@material-ui/icons/PanTool"
import ComponentLink from "../General/ComponentLink"
import DeleteButton from "../../../generalComponents/DeleteButton"
import AddBox from "@material-ui/icons/AddBox"
import { IconButton, Box, Typography } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
}))

export default function ListItemDraggable(props) {
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    device,
    singleBlockClickForSegementList,
    setSingleBlockClickForSegementList,
  } = itemContext
  const { element, onDeleteSlice, onAddSlice, deleteEnabled } = props
  // const [spacing, setSpacing] = React.useState(2);
  const menuItemHeight = element.single ? 230 : 400
  const classes = useStyles()
  const [isDrag, setIsDrag] = React.useState(false)
  //let tempStyle = new ComponentStyle;
  //let itemStyle = GetComponentList()[GetComponentList().findIndex(element => element.type === item.data.type)]
  const [itemIndex, setItemIndex] = React.useState([])
  useEffect(() => {
    setItemIndex(props.itemIndex)
  }, [props.itemIndex])
  // console.log(ComponentStyle)

  const onStart = (e, data) => {
    console.log("onstart: " + data.y)
    //data.node.style.backgroundColor = '#000'
    setIsDrag(true)
  }
  const onStop = (data, index, item) => {
    // console.log('onstop: '+data.lastY/80);

    data.node.style.zIndex = itemIndex

    const new_index = Math.round(data.lastY / menuItemHeight)
    const old_index = index
    console.log(data)
    console.log("new index -> " + new_index + " old index --> " + old_index)

    props.onStopDrag(old_index, new_index)
    props.onDragIndex(-1)
    setIsDrag(false)
  }
  const onDrag = (ind, data) => {
    //console.log('onDrag: '+ind)
    //data.node.style.zIndex = 1;
    //console.log('onDrag data: '+JSON.stringify(data.node.style.zIndex))
    console.log("onDrag: " + Math.round(data.lastY / menuItemHeight))
    props.onDragIndex(Math.round(data.lastY / menuItemHeight))
    data.node.style.zIndex = 1000
    //console.log('onDrag data2: '+JSON.stringify(data.node.style.zIndex))
  }

  console.log("isDrag: " + isDrag)
  let xPos = 0
  let yPos = itemIndex * 10

  let bckColour = isDrag ? "#48B7E6" : props.clash ? "#f00" : "#ccc"
  console.log("assets.main:", item.data.advancedSettings.assets.main)
  const getListWidth = () => {
    let { size, barMode } = item.data.advancedSettings
    let listHeight = 0
    let barHeight = 136
    if (device.data.screenHeight === 1280) {
      barHeight = 136
      if (barMode === "C") {
        barHeight = 136 * 2
      }
    } else if (device.data.screenHeight === 800) {
      barHeight = 84
      if (barMode === "C") {
        barHeight = 84 * 2
      }
    }

    listHeight = Math.round((device.data.screenHeight - barHeight) / size)
    //console.log('list height:',listHeight, size, barMode, barHeight, barMode=='C');
    return listHeight
  }

  const interactionBlocks = (
    <Grid item style={{ width: element.single ? "30%" : "90%" }}>
      <Grid
        container
        // direction="column"
        justifyContent="space-between"
        alignItems="center"
        style={{
          width: element.single ? "40%" : "100%",
          marginTop: element.single ? "0px" : "20px",
        }}
      >
        <Typography className={classes.heading}>On interaction</Typography>
      </Grid>
      <Grid
        item
        container
        spacing={20}
        justifyContent="space-between"
        style={{ width: element.single ? "30%" : "100%" }}
      >
        {element.single ? (
          <Grid item style={{ width: "25%" }}>
            <ComponentLink
              blockIndex={`${props.itemIndex}`}
              linkItem={element}
              mainItem={item}
              viewStyle={{
                width: "100%",
                height: 50,
                objectFit: "contain",
              }}
              hideExtraInterface={false}
              adjustableWidth="170px"
              isSegmentedType={true}
            />
          </Grid>
        ) : (
          ["A", "B", "C", "D"].map((char) => {
            return (
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <Box
                  sx={{
                    textAlign: {
                      xs: "start",
                      md: "center",
                    },
                    marginTop: {
                      xs: "10px",
                      md: "0px",
                    },
                  }}
                >
                  {char}
                </Box>
                <ComponentLink
                  blockIndex={`${props.itemIndex}_${char}`}
                  linkItem={element[char]}
                  mainItem={item}
                  viewStyle={{
                    width: "100%",
                    height: 50,
                    objectFit: "contain",
                  }}
                  hideExtraInterface={false}
                  adjustableWidth="170px"
                  isSegmentedType={true}
                />
              </Grid>
            )
          })
        )}
        {/* <Grid item style={{ width: "25%" }}>
    <p style={{ textAlign: "center", marginBottom: "0px" }}>A</p>
    <ComponentLink
      blockIndex={`${props.itemIndex}_A`}
      linkItem={element["A"]}
      mainItem={item}
      viewStyle={{
        width: "100%",
        height: 50,
        objectFit: "contain"
      }}
      hideExtraInterface={false}
      adjustableWidth="170px"
      isSegmentedType={true}
    />
  </Grid>
  <Grid item style={{ width: "25%" }}>
    <p style={{ textAlign: "center", marginBottom: "0px" }}>B</p>
    <ComponentLink
      blockIndex={`${props.itemIndex}_B`}
      linkItem={element["B"]}
      mainItem={item}
      viewStyle={{
        width: "100%",
        height: 50,
        objectFit: "contain"
      }}
      hideExtraInterface={false}
      adjustableWidth="170px"
      isSegmentedType={true}
    />
  </Grid>
  <Grid item style={{ width: "25%" }}>
    <p style={{ textAlign: "center", marginBottom: "0px" }}>C</p>
    <ComponentLink
      blockIndex={`${props.itemIndex}_C`}
      linkItem={element["C"]}
      mainItem={item}
      viewStyle={{
        width: "100%",
        height: 50,
        objectFit: "contain"
      }}
      hideExtraInterface={false}
      adjustableWidth="170px"
      isSegmentedType={true}
    />
  </Grid>
  <Grid item style={{ width: "25%" }}>
    <p style={{ textAlign: "center", marginBottom: "0px" }}>D</p>
    <ComponentLink
      blockIndex={`${props.itemIndex}_D`}
      linkItem={element["D"]}
      mainItem={item}
      viewStyle={{
        width: "100%",
        height: 50,
        objectFit: "contain"
      }}
      hideExtraInterface={false}
      adjustableWidth="170px"
      isSegmentedType={true}
    />
  </Grid> */}
      </Grid>
    </Grid>
  )
  return (
    <Draggable
      onStart={onStart}
      onStop={(e, obj) => {
        onStop(obj, itemIndex, item)
      }}
      inx={itemIndex}
      onDrag={(e, data) => {
        onDrag(e, data, itemIndex)
      }}
      bounds="parent"
      handle="strong"
      defaultPosition={{ x: xPos, y: yPos }}
      position={{ x: xPos, y: yPos }}
      positionOffset={null}
      scale={1}
    >
      <div
        className="handle"
        style={{
          // position: "absolute",
          backgroundColor: "white",
          width: "100%",
          zIndex: itemIndex,
          // height: menuItemHeight,
        }}
      >
        <Grid
          container
          justifyContent="left"
          style={{
            paddingTop: 0,
            // height: menuItemHeight,
            width: "100%",
            marginBottom: 0,
          }}
        >
          <Box
            container
            style={{
              width: "10%",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Grid
              container
              style={{
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              {/* <h3 style={{padding:'10%'}}>{itemIndex+1}</h3> */}
              <strong
                item
                className="cursor"
                style={{ padding: "10%", cursor: "pointer" }}
              >
                <PanTool style={{ color: bckColour }} />
              </strong>
              {deleteEnabled ? (
                <DeleteButton
                  deleteMethod={onDeleteSlice}
                  value={element}
                  className={classes.button}
                  text={"list slice"}
                />
              ) : null}
              <IconButton
                color="secondary"
                onClick={() => {
                  onAddSlice(element)
                }}
                style={{}}
              >
                <AddBox />
              </IconButton>
            </Grid>
          </Box>
          <Grid
            style={{
              width: "90%",
            }}
          >
            <Grid
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Grid item style={{ width: element.single ? "50%" : "75%" }}>
                <Grid container style={{ width: "100%" }}>
                  <Typography className={classes.heading}>
                    Slice image
                  </Typography>
                </Grid>
                <SelectAsset
                  viewStyle={{
                    width: "100%",
                    height: 50,
                    objectFit: "contain",
                  }}
                  assetRequirements={{
                    presentMatches: false,
                    data: [
                      {
                        type: "jpg",
                        width:
                          item.data.orientation === "Portrait" ||
                          item.data.orientation === undefined
                            ? device.data.screenWidth
                            : getListWidth(),
                        height:
                          item.data.orientation === "Portrait" ||
                          item.data.orientation === undefined
                            ? getListWidth()
                            : device.data.screenWidth,
                      },
                      {
                        type: "png",
                        width:
                          item.data.orientation === "Portrait" ||
                          item.data.orientation === undefined
                            ? device.data.screenWidth
                            : getListWidth(),
                        height:
                          item.data.orientation === "Portrait" ||
                          item.data.orientation === undefined
                            ? getListWidth()
                            : device.data.screenWidth,
                      },
                    ],
                  }}
                  asset={element.assets}
                  onSelect={(meta, imgName) => {
                    console.log("setting -> src --> ", meta)
                    console.log("setting -> name --> ", imgName)
                    element.assets = {
                      meta: meta,
                      name: imgName,
                    }
                    updateItem(item, previewMode)
                  }}
                />
              </Grid>

              {element.single ? (
                <Box
                  sx={{
                    display: {
                      xs: "none",
                      lg: "block",
                    },
                  }}
                >
                  {interactionBlocks}
                </Box>
              ) : null}

              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={element.single}
                      onChange={(_, value) => {
                        // console.log("value", value);

                        item.data.advancedSettings.listItems[
                          props.itemIndex
                        ].single = value

                        setSingleBlockClickForSegementList(true)

                        // if (value) {
                        //   // const data =
                        //   //   item.data.advancedSettings.listItems[itemIndex]["A"]
                        //   // delete item.data.advancedSettings.listItems[
                        //   //   itemIndex
                        //   // ]["A"]
                        //   // delete item.data.advancedSettings.listItems[
                        //   //   itemIndex
                        //   // ]["B"]
                        //   // delete item.data.advancedSettings.listItems[
                        //   //   itemIndex
                        //   // ]["C"]
                        //   // delete item.data.advancedSettings.listItems[
                        //   //   itemIndex
                        //   // ]["D"]
                        //   // item.data.advancedSettings.listItems[itemIndex] = {
                        //   //   ...item.data.advancedSettings.listItems[itemIndex],
                        //   //   single: value,
                        //   //   // ...data,
                        //   // }
                        //   // let newSelected = []
                        //   // item.data.advancedSettings.selected.forEach(
                        //   //   (select) => {
                        //   //     if (select.includes(element.index)) {
                        //   //       const data = select
                        //   //         .split(",")
                        //   //         .filter((val) => !val.includes(element.index))
                        //   //       if (data.length > 0) {
                        //   //         newSelected.push(data.join(","))
                        //   //       }
                        //   //     } else {
                        //   //       newSelected.push(select)
                        //   //     }
                        //   //   }
                        //   // )
                        //   // const {
                        //   //   link,
                        //   //   backToPrevious,
                        //   //   exitTour,
                        //   //   toKeypad,
                        //   //   returnLink,
                        //   // } = item.data.advancedSettings.listItems[itemIndex]
                        //   // if (
                        //   //   link ||
                        //   //   backToPrevious ||
                        //   //   exitTour ||
                        //   //   toKeypad ||
                        //   //   returnLink
                        //   // ) {
                        //   //   newSelected.push(`${element.index}`)
                        //   // }
                        //   // item.data.advancedSettings.selected = newSelected
                        // } else {
                        //   // const { title, index, single, assets, ...data } =
                        //   //   item.data.advancedSettings.listItems[itemIndex]
                        //   // item.data.advancedSettings.listItems[itemIndex] = {
                        //   //   title,
                        //   //   index,
                        //   //   assets,
                        //   //   single: value,
                        //   //   A: {
                        //   //     ...data,
                        //   //   },
                        //   //   B: {
                        //   //     link: null,
                        //   //   },
                        //   //   C: {
                        //   //     link: null,
                        //   //   },
                        //   //   D: {
                        //   //     link: null,
                        //   //   },
                        //   // }
                        //   // item.data.advancedSettings.selected =
                        //   //   item.data.advancedSettings.selected.filter(
                        //   //     (val) => val !== `${element.index}`
                        //   //   )
                        //   // const {
                        //   //   link,
                        //   //   backToPrevious,
                        //   //   exitTour,
                        //   //   toKeypad,
                        //   //   returnLink,
                        //   // } =
                        //   //   item.data.advancedSettings.listItems[itemIndex]["A"]
                        //   // if (
                        //   //   link ||
                        //   //   backToPrevious ||
                        //   //   exitTour ||
                        //   //   toKeypad ||
                        //   //   returnLink
                        //   // ) {
                        //   //   item.data.advancedSettings.selected.push(
                        //   //     `${element.index}_A`
                        //   //   )
                        //   // }
                        // }

                        updateItem(item, previewMode)
                      }}
                      color="primary"
                    />
                  }
                  label="Single"
                />
              </Grid>
            </Grid>

            {element.single ? (
              <Box
                sx={{
                  display: {
                    xs: "block",
                    lg: "none",
                  },
                }}
              >
                {interactionBlocks}
              </Box>
            ) : (
              interactionBlocks
            )}
          </Grid>
        </Grid>
      </div>
    </Draggable>
  )
}
